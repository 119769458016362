
import { DocumentImporterContext } from "@/hooks/document-importer";
import { DOCUMENT_IMPORTER_CONTEXT_KEY } from "./common";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon, RefreshIcon } from "@heroicons/vue/solid";
import { computed, inject, Ref } from "vue";
import Spinner from "@/components/spinner.vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    CheckIcon,
    RefreshIcon,
    Spinner,
  },
  emits: ["update:selectedScanner", "update:sheetFeederEnabled"],
  setup() {
    const importerContext = inject<Ref<DocumentImporterContext>>(
      DOCUMENT_IMPORTER_CONTEXT_KEY
    );

    return {
      importer: computed(() =>
        importerContext && importerContext.value
          ? importerContext.value.importer
          : null
      ),
    };
  },
};
