import { computed, reactive } from "vue";
import { useRoute } from "vue-router";

export const useParentWindow = () => {
  const isFramed = computed(() => {
    try {
      return window && window.parent !== window;
    } catch (thrown) {
      return false;
    }
  });

  const policySearchDisabled = computed(() => {
    const route = useRoute();

    if (route) {
      return (
        (isFramed.value &&
          !!route.query.policyType &&
          !!route.query.policyNumber) ||
        false
      );
    }

    return false;
  });

  const postMessage = (message: Record<string, any>) => {
    try {
      if (!isFramed.value) {
        return false;
      }

      window.parent.postMessage(message, "*");
    } catch (thrown) {
      console.error(thrown);
      return false;
    }
  };

  return reactive({ isFramed, policySearchDisabled, postMessage });
};
