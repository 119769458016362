import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    id: _ctx.id,
    role: "input",
    class: ["relative z-0 inline-flex rounded-md shadow-sm", { [_ctx.gridColsClass]: true }]
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.normOptions, (option, index) => {
      return (_openBlock(), _createBlock("button", {
        key: option.id,
        type: "button",
        class: ["relative inline-flex items-center justify-center px-4 py-2 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 whitespace-nowrap", {
        'rounded-l-md': index === 0,
        '-ml-px': index > 0 && index < _ctx.normOptions.length - 1,
        '-ml-px rounded-r-md': index == _ctx.normOptions.length - 1,
        'bg-blue-500 text-white border border-blue-500 hover:bg-blue-600 focus:ring-blue-500 focus:border-blue-500':
          _ctx.value === option.id,
        'bg-white hover:bg-gray-100 border text-gray-500 border-gray-300 focus:ring-blue-500 focus:border-blue-500':
          _ctx.value !== option.id,
      }],
        onClick: ($event: any) => {
        _ctx.$emit('update:modelValue', option.id);
        _ctx.$emit('update:selectedOption', option);
      },
        textContent: _toDisplayString(option.title)
      }, null, 10, ["onClick", "textContent"]))
    }), 128))
  ], 10, ["id"]))
}