
import { computed, ref, defineComponent } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'SelectInput',
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  emits: ['update:modelValue', 'update:selectedOption'],
  props: {
    id: { type: String },
    value: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const normOptions = computed(() =>
      props.options
        .map((value, index) => {
          // if (typeof item !== "object") {
          //   return undefined;
          // }
          const option: any = value;
          return {
            id: option.id || `_-_${index}_-_`,
            title: option.title || '',
          };
        })
        .filter((i) => !!i)
    );

    const selected = computed(() =>
      normOptions.value.find((i) => i.id === props.value)
    );

    return {
      normOptions,
      selected,
    };
  },
});
