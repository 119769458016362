import { readonly, ref } from 'vue';

const launchParams = ref<Record<string, any>>({});

export const createLaunchParameters = (parms: Record<string, any>) => {
  launchParams.value = parms
}

export const useLaunchParameters = () => {
  return readonly(launchParams)
}
