import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import NotFound from '../views/NotFound.vue';
import Unauthorized from '../views/Unauthorized.vue';
import Importer from '../views/PolicyDocumentImporter.vue';
import PolicyDocumentsViewer from '../views/PolicyDocumentsViewer.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: 'default-layout' },
  },
  // {
  //   path:
  //     '/policies/lookup',
  //   name: 'documents-with-params',
  //   component: PolicyDocumentsViewer,
  //   meta: { layout: 'default-layout' },
  // },
  {
    path: '/policies/lookup',
    name: 'documents',
    component: PolicyDocumentsViewer,
    meta: { layout: 'default-layout' },
  },
  {
    path: '/policies/import',
    name: 'import',
    component: Importer,
    meta: { layout: 'default-layout' },
  },
  {
    path:
      '/policies/import?sessionId=:sessionId&policyNumber=:policyNumber&policyType=:policyType',
    name: 'import-with-params',
    component: Importer,
    meta: { layout: 'default-layout' },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
    meta: { layout: 'default-layout' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { layout: 'default-layout' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
