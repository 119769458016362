import { createLogger } from '@/logger';
import { reactive, readonly, ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import { useData } from './data';
import { useSession } from './session';

const createEmptyPolicy = () => ({
  policyNumber: '',
  expandedPolicyNumber: '',
  namedInsured: '',
});

export function usePolicyValidator() {
  const loading = ref(false);
  const found = ref(false);
  const policy = ref<Record<string, string>>(createEmptyPolicy());

  const model = {
    loading: computed(() => loading.value),
    found: computed(() => found.value),
    policy: computed(() => (found.value ? readonly(policy.value) : null)),
  };

  function parsePolicyNumber(s: string) {
    const t = new RegExp(
      /([a-zA-Z][a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9])([-\s])?(\d*)(?:[[:ascii:]]*)?$/i
    );
    const r = t.exec(s);

    if (r) {
      return {
        prefix: r[1],
        suffix: r[3],
      };
    } else {
      return {
        prefix: '',
        suffix: '',
      };
    }
  }

  async function load(policyNumber: string) {
    const parts = parsePolicyNumber(policyNumber);

    if (policyNumber === policy.value.policyNumber) {
      return;
    }

    try {
      loading.value = true;

      const dataService = useData();
      const session = useSession();

      const validationResult = await dataService.invokeStandardMethod(
        'IMAGERTR_VALIDATEPOLICY',
        {
          session: {
            sessionId: session.id,
          },
          parameters: {
            policyPrefix: parts.prefix,
            policyNumber: parseInt(parts.suffix),
          },
        }
      );

      const valid =
        validationResult.data.outputResult?.data?.token?.valid || false;

      if (valid) {
        found.value = true;
        policy.value = readonly({
          policyNumber: policyNumber,
          expandedPolicyNumber:
            validationResult.data.outputResult.data.token.policy || '',
          namedInsured:
            validationResult.data.outputResult.data.token.namedInsured || '',
        });
      } else {
        found.value = false;
        policy.value = readonly(createEmptyPolicy());
      }

      loading.value = false;
    } catch (e) {
      createLogger('policy-validator:load').error(e);
      found.value = false;
      policy.value = readonly(createEmptyPolicy());
      loading.value = false;
    }
  }

  return reactive({
    ...model,
    load,
  });
}
