import { withKeys as _withKeys, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-flex items-center " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")

  return (_openBlock(), _createBlock("span", _hoisted_1, [
    _withDirectives(_createVNode("input", {
      type: "text",
      name: _ctx.name,
      id: _ctx.id,
      autocomplete: _ctx.autocomplete,
      readonly: _ctx.loading || _ctx.readonly,
      class: ["flex-grow block w-full border-gray-300 rounded-md shadow-sm sm:text-sm", {
        'focus:ring-transparent focus:border-gray-300': _ctx.readonly,
        'focus:ring-blue-500 focus:border-blue-500': !_ctx.readonly,
        'bg-transparent text-gray-500 pr-10 border-opacity-60': _ctx.readonly,
        'bg-white border-red-500': !_ctx.readonly && !_ctx.loading && _ctx.invalid,
        'text-gray-400': _ctx.loading,
      }],
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event))),
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      onKeypress: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)), ["enter"])),
      value: _ctx.value
    }, null, 42, ["name", "id", "autocomplete", "readonly", "value"]), [
      [_directive_maska, _ctx.mask]
    ])
  ]))
}