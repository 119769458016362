import { reactive, ref, watch } from "vue";
import { usePolicyValidator } from "../policy-validator";
import { useForm, Form, FormFieldType } from "../reactive-form";
import { Ai } from "./types";
const ACCEPTANCE_POLICY_TYPE = "acceptance";

export function createPolicyDocumentSearchForm() {
  const policyValidator = usePolicyValidator();
  const expandedPolicyNumber = ref("");
  const validPolicyNumber = ref(true);

  const form = reactive<Form<Ai.Documents.DocumentTreeFormData>>(
    useForm<{ policyNumber: string; policyType: string }>({
      fields: [
        {
          name: "policyType",
          type: FormFieldType.string,
          label: "Policy Type",
          validation: {
            required: true,
          },
          selections: [
            { id: "acceptance", title: "Acceptance" },
            { id: "3PC", title: "3rd Party (3PC)" },
          ],
        },
        {
          name: "policyNumber",
          type: FormFieldType.string,
          label: "Policy Number",
          validation: {
            required: true,
          },
          customValidator: () => {
            return validPolicyNumber.value ? true : "Invalid Policy Number";
          },
        },
      ],
      value: {
        policyType: ACCEPTANCE_POLICY_TYPE,
        policyNumber: "",
      },
    })
  );

  const policyTypeField = form.fields.find((i) => i.name === "policyType");
  const policyNumberField = form.fields.find((i) => i.name === "policyNumber");

  watch(policyValidator, () => {
    policyNumberField!.loading = policyValidator.loading;
    (expandedPolicyNumber.value as any) = policyNumberField!.value || "";

    if (policyValidator.loading === false) {
      validPolicyNumber.value = policyValidator.found;
      if (policyValidator.found) {
        policyNumberField!.value =
          policyValidator.policy!.expandedPolicyNumber ||
          policyNumberField?.value ||
          "";
      }
    } else {
      validPolicyNumber.value = true;
    }
  });

  watch(form, () => {
    if (policyTypeField!.value === ACCEPTANCE_POLICY_TYPE) {
      if (policyNumberField!.value !== expandedPolicyNumber.value) {
        policyValidator.load((form.data.policyNumber as string) || "");
      }
    } else {
      validPolicyNumber.value = true;
    }
  });

  return form;
}
