
import { DocumentImporterContext } from '@/hooks/document-importer';
import { DOCUMENT_IMPORTER_CONTEXT_KEY } from './common';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { computed, inject, reactive, ref, Ref, watch } from 'vue-demi';
import ProgressCircle from '../progress-circle.vue';

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ProgressCircle,
  },
  emits: ['save-clicked', 'cancel-clicked'],
  setup(props, { emit }) {
    const importerContext = inject<Ref<DocumentImporterContext>>(
      DOCUMENT_IMPORTER_CONTEXT_KEY
    );

    if (!importerContext) {
      throw new Error('Importer context is required in record actions.');
    }

    const saveDisabled = computed(
      () =>
        importerContext?.value &&
        (!importerContext.value.canUpload ||
          importerContext.value.uploading ||
          importerContext.value.importer.isScanning ||
          importerContext.value.importer.isLoadingImages ||
          importerContext.value.importer.isConvertingImages)
    );

    const uploadPercentComplete = ref(0);

    watch(
      importerContext,
      () => {
        uploadPercentComplete.value =
          importerContext.value.uploadProgress.percentComplete * 100;
      },
      { deep: true }
    );

    const items = [
      reactive({
        name: 'Save',
        disabled: saveDisabled,
        onClick: () => emit('save-clicked', true),
      }),
      {
        name: 'Cancel',
        disabled: false,
        onClick: () => emit('cancel-clicked', true),
      },
    ];

    return {
      items,
      saveDisabled,
      uploadPercentComplete,
      importerContext,
    };
  },
};
