
import { computed, defineComponent, ref } from '@vue/runtime-core';

export default defineComponent({
  name: 'ButtonGroup',
  emits: ['update:modelValue', 'update:selectedOption'],
  props: {
    id: { type: String },
    value: { type: String, default: '' },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const normOptions = computed<{ id: string; title: string }[]>(() =>
      props.options
        .map((value, index) => {
          // if (typeof item !== "object") {
          //   return undefined;
          // }
          const option: any = value;
          return {
            id: option.id || `_-_${index}_-_`,
            title: option.title || '',
          };
        })
        .filter((i) => !!i)
    );

    // grid-cols-1, grid-cols-2, grid-cols-3, grid-cols-4, grid-cols-5, grid-cols-6
    const gridColsClass = ref(`grid-cols-${normOptions.value.length}`);

    return {
      gridColsClass,
      normOptions,
    };
  },
});
