export const validateMaxValue = (
  value: number,
  maxValue: number,
  required?: boolean | null
): string | true => {
  if (!required && value === null) {
    return true;
  }

  if (!isFinite(maxValue)) {
    return true;
  }

  if (value > maxValue) {
    return `Must not exceed ${maxValue}`;
  }

  return true;
};
