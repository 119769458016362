
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue-demi';
import TWEEN, { Tween } from '@tweenjs/tween.js';

export default defineComponent({
  props: {
    number: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const state = reactive({ currentNumber: ref(0) });
    const tween = ref();

    onMounted(() => {
      watch(props, () => {
        if (tween.value) {
          tween.value.end();
        }

        function animate() {
          if (TWEEN.update()) {
            requestAnimationFrame(animate);
          }
        }

        tween.value = new TWEEN.Tween<{ currentNumber: number }>(state)
          .to({ currentNumber: props.number }, 2000)
          .start();

        animate();
      });
    });

    onUnmounted(() => {
      if (tween.value) {
        tween.value.stop();
        tween.value.end();
      }
    });

    return {
      formattedNumber: computed(() => state.currentNumber.toFixed(0)),
    };
  },
});
