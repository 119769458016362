import dayjs from "dayjs";

export const validateMaxDate = (
  value: string,
  maxDate: string,
  displayFormat: string,
  required?: boolean | null
): string | true => {
  if (!required && value === "") {
    return true;
  }

  const v = dayjs(value);

  if (!v.isValid()) {
    // Handled by date validator
    return true;
  }

  if (!v.isBefore(dayjs(maxDate).add(1, "day"))) {
    return `Must be on or before ${dayjs(maxDate).format(displayFormat)}`;
  }

  return true;
};
