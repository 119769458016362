
import { defineComponent } from 'vue';
import Spinner from './spinner.vue';
export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {},
  components: { Spinner },
});
