import { createLogger } from '@/logger';
import { reactive, readonly, ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import { useData } from './data';
import { useSession } from './session';

export interface ProductType {
  id: string;
  title: string;
}

const items = ref<ProductType[]>([]);

export function useProductTypes() {
  const loading = ref(false);

  const model = {
    loading: computed(() => loading.value),
    items: computed(() => [...items.value]),
  };

  async function load() {
    try {
      loading.value = true;

      const dataService = useData();
      const session = useSession();

      const { data } = await dataService.invokeStandardMethod(
        'IMAGERTR_GETSCANNINGPRODUCTTYPES',
        {
          session: {
            sessionId: session.id,
          },
        }
      );

      const src = data.outputResult.data.productTypes;
      if (Array.isArray(src)) {
        items.value = src.map((i) => ({
          id: i.id || '',
          title: i.description,
        }));
      }

      loading.value = false;
    } catch (e) {
      createLogger('product-types:load').error(e);
      loading.value = false;
    }
  }

  return reactive({
    ...model,
    load,
  });
}
