
import { defineComponent } from 'vue';
import ProgressCircle from './progress-circle.vue';
import ProgressSpinner from './spinner.vue';

export default defineComponent({
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {},
  components: { ProgressCircle, ProgressSpinner },
});
