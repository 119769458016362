
import { computed, defineComponent, reactive, inject, Ref } from "vue";
import TextInput from "@/components/inputs/text.vue";
import SelectInput from "@/components/inputs/select.vue";
import Spinner from "@/components/spinner.vue";

import {
  LockClosedIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/vue/solid";

import { FormField, Form } from "@/hooks/reactive-form";
import { DocumentImporterContext } from "@/hooks/document-importer";
import { DOCUMENT_IMPORTER_CONTEXT_KEY } from "./common";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MetaForm",
  components: {
    LockClosedIcon,
    ExclamationCircleIcon,
    CheckCircleIcon,
    Spinner,
  },
  emits: ["update:modelValue"],
  setup() {
    const importerContext = inject<Ref<DocumentImporterContext>>(
      DOCUMENT_IMPORTER_CONTEXT_KEY
    );

    const selectFieldNames = ["productType", "policyType", "documentType"];

    function resolveFieldComponent(field: FormField) {
      if (selectFieldNames.includes(field.name)) {
        return SelectInput;
      }
      return TextInput;
    }

    function resolveFieldComponentProps(field: FormField) {
      const base = {
        id: `a-meta-field-${field.name}`,
        name: field.name,
        loading: computed(() => field.loading),
        readonly: computed(() => field.disabled),
        invalid: computed(() => field.invalid),
        value: computed(() => field.value),
      };

      // This should be handled by the component parent
      if (field.name === "policyNumber") {
        if (
          importerContext?.value.metaForm.data.documentType === "acceptance"
        ) {
          (base as any).mask = {
            mask: "AXXX-#*",
            tokens: {
              A: { pattern: /[a-zA-z]/, uppercase: true },
              X: { pattern: /[0-9a-zA-z]/, uppercase: true },
            },
          };
        } else {
          (base as any).mask = {
            mask: "X*",
            tokens: {
              X: { pattern: /[0-9a-zA-z\s-]/, uppercase: true },
            },
          };
        }
      }

      if (field.selections?.length) {
        return reactive({
          ...base,
          options: computed(() => field.selections),
        });
      }

      return reactive(base);
    }

    const productTypeField = importerContext?.value.metaForm?.fields.find(
      (i) => i.name === "productType"
    );
    const documentTypeField = importerContext?.value.metaForm?.fields.find(
      (i) => i.name === "documentType"
    );

    const { params } = useRoute();

    if (productTypeField && params["productType"]) {
      productTypeField.disabled = true;
    }
    if (documentTypeField && params["documentType"]) {
      documentTypeField.disabled = true;
    }

    return {
      fields: computed(() =>
        importerContext && importerContext.value
          ? importerContext.value.metaForm.fields
          : []
      ),
      resolveFieldComponent,
      resolveFieldComponentProps,
    };
  },
});
