
import { useParentWindow } from '@/hooks/parent-window';
import { defineComponent, onMounted, ref } from 'vue-demi';
import { useNetwork } from '@vueuse/core';

export default defineComponent({
  setup() {
    return { parentWindow: useParentWindow() };
  },
});
