import dayjs from 'dayjs';
import { computed, reactive, ref } from 'vue';
import { validateMaxDate } from './validators/max-date';
import { validateMaxLength } from './validators/max-length';
import { validateMaxValue } from './validators/max-value';
import { validateMinDate } from './validators/min-date';
import { validateMinLength } from './validators/min-length';
import { validateMinValue } from './validators/min-value';
import { validateOneOfSelections } from './validators/one-of';
import { validateRequiredValue } from './validators/required';

import {
  BooleanFieldOptions,
  DateFieldOptions,
  FieldValidator,
  Form,
  FormField,
  FormFieldOptions,
  FormFieldType,
  FormOptions,
  NumberFieldOptions,
  TextFieldOptions,
} from './index';

/** Returns true if all fields in the form are valid */
export type FormFieldIndicatorStatus =
  | 'normal'
  | 'success'
  | 'failure'
  | 'warning';

const validators: Record<string, FieldValidator> = {
  required: (field, options) => validateRequiredValue(field.value),
  minDate: (field, options) =>
    validateMinDate(
      field.value as string,
      options.validation?.minDate,
      'MM/DD/YYYY',
      options.validation?.required || false
    ),
  minLength: (field, options) =>
    validateMinLength(
      field.value as string,
      options.validation?.minLength,
      '',
      options.validation?.maxLength
    ),
  minValue: (field, options) =>
    validateMinValue(
      field.value as number,
      options.validation?.minValue,
      options.validation?.maxValue,
      options.validation?.required
    ),
  maxDate: (field, options) =>
    validateMaxDate(
      field.value as string,
      options.validation?.maxDate,
      'MM/DD/YYYY',
      options.validation?.required
    ),
  maxLength: (field, options) =>
    validateMaxLength(
      field.value as string,
      options.validation?.maxLength,
      '',
      options.validation?.required
    ),
  maxValue: (field, options) =>
    validateMaxValue(
      field.value as number,
      options.validation?.maxValue,
      options.validation?.required
    ),
  oneOfSelections: (field, options) =>
    validateOneOfSelections(
      field.value,
      (field.selections && field.selections.map((item) => item.id)) || []
    ),
};

const stringValidators = [
  validators.required,
  validators.minLength,
  validators.maxLength,
];

const numberValidators = [
  validators.required,
  validators.minValue,
  validators.maxValue,
];

const booleanValidators = [validators.required];

const dateValidators = [
  validators.required,
  validators.minDate,
  validators.maxDate,
];

function getFieldValidators(field: FormField): FieldValidator[] {
  switch (field.type) {
    case FormFieldType.string:
      return stringValidators;
    case FormFieldType.number:
      return numberValidators;
    case FormFieldType.date:
      return dateValidators;
    case FormFieldType.boolean:
      return booleanValidators;
    default:
      return [];
  }
}

export function useForm<T extends Record<string, any>>(
  formOptions: FormOptions<T>
): Form<T> {
  const touchedFields = ref(new Set<FormField>());
  const loadingFields = ref(new Set<FormField>());
  const disabledFields = ref(new Set<FormField>());
  const indicatorStatus = ref(new Map<FormField, FormFieldIndicatorStatus>());
  const fieldValidators = ref(new Map<FormField, () => true | string>());

  function createFieldValidator(
    field: FormField,
    fieldOptions: FormFieldOptions
  ): () => true | string {
    if (!fieldOptions.validation && !fieldOptions.customValidator) {
      return () => true;
    }

    if (fieldOptions.validation) {
      return () => {
        let failureText: string = '';

        if (field.disabled) {
          return true;
        }

        getFieldValidators(field).find((validator) => {
          const valResult = validator(field, fieldOptions);

          if (valResult === true) {
            return false;
          }

          failureText = valResult as string;
          return true;
        });

        if (failureText) {
          return failureText;
        }

        if (fieldOptions.customValidator) {
          return fieldOptions.customValidator(field);
        }

        return true;
      };
    }

    if (fieldOptions.customValidator) {
      return () =>
        fieldOptions.customValidator
          ? fieldOptions.customValidator(field)
          : true;
    }

    return () => {
      return true;
    };
  }

  function createFieldValueComputedProperty(
    fieldOptions: FormFieldOptions,
    instanceResolver: () => FormField
  ) {
    const fieldValue = ref();
    let creating = false;

    function getProperty() {
      switch (fieldOptions.type) {
        case FormFieldType.string:
          return computed<string>({
            get: () => {
              if (fieldValue.value !== undefined && fieldValue.value !== null) {
                return fieldValue.value;
              }
              return '';
            },
            set: (value: boolean | string | number) => {
              if (!creating && disabledFields.value.has(instanceResolver())) {
                return;
              }

              if (value !== undefined && value !== null) {
                fieldValue.value = value.toString();
              }
            },
          });

        case FormFieldType.number:
          return computed<number>({
            get: () => {
              if (fieldValue.value !== undefined && fieldValue.value !== null) {
                return fieldValue.value;
              }
              return null;
            },
            set: (value: number) => {
              if (!creating && disabledFields.value.has(instanceResolver())) {
                return;
              }

              if (typeof value === 'number') {
                fieldValue.value = value;
              } else if (typeof value === 'string') {
                const converted = parseFloat(value);
                if (!isNaN(converted)) {
                  fieldValue.value = converted;
                }
              }
            },
          });

        case FormFieldType.date:
          return computed<string>({
            get: () => {
              if (fieldValue.value !== undefined && fieldValue.value !== null) {
                if (dayjs(fieldValue.value).isValid()) {
                  return fieldValue.value;
                }
              }
              return '';
            },
            set: (value: string | Date) => {
              if (!creating && disabledFields.value.has(instanceResolver())) {
                return;
              }

              if (
                ((typeof value !== 'boolean' && typeof value === 'object') ||
                  typeof value === 'string') &&
                dayjs(value).isValid()
              ) {
                fieldValue.value = dayjs(value).toISOString();
              } else {
                fieldValue.value = '';
              }
            },
          });

        case FormFieldType.boolean:
          return computed<boolean>({
            get: () => {
              if (typeof fieldValue.value === 'boolean') {
                return fieldValue.value || false;
              }

              return false;
            },
            set: (value: boolean | string) => {
              if (!creating && disabledFields.value.has(instanceResolver())) {
                return;
              }

              let normValue: boolean = false;

              if (typeof value === 'string') {
                normValue = value.toLocaleLowerCase() === 'true';
              } else if (typeof value === 'boolean') {
                normValue = value;
              }

              fieldValue.value = normValue;
            },
          });
        default:
          throw new Error(`Invalid field type '${fieldOptions.type}'`);
      }
    }

    const prop = getProperty();

    if (typeof formOptions.value === 'object') {
      creating = true;
      prop.value = formOptions.value[fieldOptions.name];
      creating = false;
    }

    return prop;
  }

  function createFormField(
    options:
      | FormFieldOptions
      | TextFieldOptions
      | NumberFieldOptions
      | DateFieldOptions
      | BooleanFieldOptions
  ) {
    const field: FormField = reactive({
      name: options.name,
      type: options.type,
      label: options.label,
      indicatorStatus: computed(
        () => indicatorStatus.value.get(field) || 'normal'
      ),
      hint: options.hint || '',
      invalid: computed(() => {
        const validator = fieldValidators.value.get(field);
        return (validator && typeof validator() === 'string') || false;
      }),
      invalidText: computed(() => {
        const validator = fieldValidators.value.get(field);
        const result = validator && validator();
        if (typeof result === 'string') {
          return result;
        }

        return '';
      }),
      selections: computed(() => {
        if (typeof options.selections === 'function') {
          return options.selections() || [];
        }

        return options.selections || [];
      }),
      disabled: computed({
        get: () => disabledFields.value.has(field),
        set: (value: boolean) => {
          if (value) {
            disabledFields.value.add(field);
          } else {
            disabledFields.value.delete(field);
          }
        },
      }),
      loading: computed({
        get: () => loadingFields.value.has(field),
        set: (value: boolean) => {
          if (value) {
            loadingFields.value.add(field);
          } else {
            loadingFields.value.delete(field);
          }
        },
      }),
      touched: computed({
        get: () => !!touchedFields.value.has(field),
        set: (value: boolean) => {
          if (value) {
            touchedFields.value.add(field);
          } else {
            touchedFields.value.delete(field);
          }
        },
      }),
      value: createFieldValueComputedProperty(options, () => field),
    });

    fieldValidators.value.set(field, createFieldValidator(field, options));

    return field;
  }

  const fields = ref<FormField[]>(
    formOptions.fields.map((option) => createFormField(option))
  );

  const form = reactive({
    invalid: computed(() => {
      return !!fields.value.find((field) => field.invalid);
    }),
    data: computed<T>({
      get() {
        return Object.fromEntries(
          fields.value.map((field) => [field.name, field.value])
        ) as T;
      },
      set(value: T) {
        Object.keys(value).forEach((key) => {
          const field = fields.value.find((field) => field.name === key);
          if (field) {
            field.value = value[key];
          }
        });
      },
    }),
    fields: computed<FormField[]>(() => [...fields.value]),
    getField: (name: string) =>
      fields.value.find((field) => field.name === name),
    touchAll() {
      fields.value.forEach((field) => {
        field.touched = true;
      });
    },
    untouchAll() {
      fields.value.forEach((field) => {
        field.touched = false;
      });
    },
  });

  return form;
}
