import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative mt-1" }
const _hoisted_2 = { class: "block truncate" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorIcon = _resolveComponent("SelectorIcon")
  const _component_ListboxButton = _resolveComponent("ListboxButton")
  const _component_CheckIcon = _resolveComponent("CheckIcon")
  const _component_ListboxOption = _resolveComponent("ListboxOption")
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")
  const _component_Listbox = _resolveComponent("Listbox")

  return (_openBlock(), _createBlock(_component_Listbox, {
    as: "div",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.id))),
    id: _ctx.id,
    value: _ctx.selected
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, {
          class: ["relative w-full py-2 pl-3 pr-10 text-left border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none sm:text-sm", {
          'focus:ring-transparent focus:border-gray-300': _ctx.readonly,
          'focus:ring-1 focus:ring-blue-500 focus:border-blue-500 bg-white': !_ctx.readonly,
          'bg-transparent text-gray-500 pr-10 border-opacity-60': _ctx.readonly,
          'bg-white border-red-500': !_ctx.readonly && !_ctx.loading && _ctx.invalid,
          'text-gray-400': _ctx.loading,
        }]
        }, {
          default: _withCtx(() => [
            _createVNode("span", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.selected && _ctx.selected.title) + " ", 1),
              (!_ctx.selected || !_ctx.selected.title)
                ? (_openBlock(), _createBlock("span", _hoisted_3, " "))
                : _createCommentVNode("", true)
            ]),
            _createVNode("span", _hoisted_4, [
              _createVNode(_component_SelectorIcon, {
                class: "w-5 h-5 text-gray-400",
                "aria-hidden": "true"
              })
            ])
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_Transition, {
          "leave-active-class": "transition duration-100 ease-in",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ListboxOptions, { class: "absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.normOptions, (option) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    as: "div",
                    key: option.id,
                    value: option
                  }, {
                    default: _withCtx(({ active }) => [
                      _createVNode("li", {
                        class: {
                'relative py-2 pl-8 pr-4 cursor-default select-none': true,
                'text-white bg-blue-600': active,
                'text-gray-900': !active,
              }
                      }, [
                        _createVNode("span", {
                          class: [
                  option.id === _ctx.value ? 'font-semibold' : 'font-normal',
                  'block truncate',
                ]
                        }, _toDisplayString(option.title), 3),
                        (option.id === _ctx.value)
                          ? (_openBlock(), _createBlock("span", {
                              key: 0,
                              class: [
                  active ? 'text-white' : 'text-blue-600',
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                ]
                            }, [
                              _createVNode(_component_CheckIcon, {
                                class: "w-5 h-5",
                                "aria-hidden": "true"
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["id", "value"]))
}