import { useData } from "./data";
import { StandardMethodResponse, DataServicesResponse } from "./data/types";

import { Ai } from "./document-tree/types";
import { computed, reactive } from "vue-demi";
import { readonly, Ref, ref, watch } from "vue";
import { useSession } from "./session";
import { createLogger } from "@/logger";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

import prettyBytes from "pretty-bytes";
import axios from "axios";

function createStreamWorker(options: {
  documentToken: string;
}): Ai.AsyncWorkerHandle<Blob | null> {
  const state = reactive({
    completed: ref(false),
    percentComplete: ref<number | null>(null),
    error: ref<any>(null),
    busy: ref<boolean>(false),
    canceled: ref(false),
    startTime: ref<Dayjs>(dayjs()),
    endTime: ref<Dayjs | null>(null),
    result: ref<Blob | null>(null),
    start,
    cancel,
  });

  const cancelSource = axios.CancelToken.source();
  const startPromise = ref<Promise<boolean> | null>(null);

  async function start() {
    if (startPromise.value) {
      return await startPromise.value;
    }

    state.busy = true;

    startPromise.value = new Promise<boolean>(async (resolve) => {
      try {
        let byteString = "";
        let startPosition = 0;
        let moreData = true;
        let streamLength = 0;

        const dataService = useData();
        const session = useSession();

        while (moreData && !state.canceled) {
          const response = (await dataService.invokeStandardMethod(
            "ImageRtr_StreamDocumentToken",
            {
              session: { sessionId: session.id },
              parameters: {
                documentToken: options.documentToken,
                startPosition,
              },
            },
            {
              cancelToken: cancelSource.token,
            }
          )) as DataServicesResponse<
            StandardMethodResponse<Ai.Documents.StreamData>
          >;

          const streamData = response.data.outputResult.data;

          if (streamData) {
            moreData = streamData.moreData;
            startPosition = streamData.startPosition;
            streamLength += streamData.stream.length;
            byteString += atob(streamData.stream);
            state.percentComplete = streamData.percentComplete;
          } else {
            moreData = false;
          }
        }

        const buffer = new ArrayBuffer(byteString.length);
        const arry = new Uint8Array(buffer);

        for (let i = 0; i < byteString.length; i++) {
          arry[i] = byteString.charCodeAt(i);
        }

        state.endTime = dayjs();
        state.completed = true;
        state.result = new Blob([buffer]);

        resolve(true);
      } catch (reason) {
        state.endTime = dayjs();

        if (axios.isCancel(reason)) {
          state.canceled = true;
          createLogger("documents:streamFile:canceled").info(reason.message);
        } else {
          state.error = reason;
        }
        resolve(false);
      } finally {
        startPromise.value = null;
        state.busy = false;
      }
    });

    return await startPromise.value;
  }

  function cancel() {
    if (!state.busy || state.canceled) return;

    cancelSource.cancel(
      `Document (${options.documentToken}) download canceled at  ${state.percentComplete}% complete.`
    );
    state.canceled = true;
  }

  return state;
}

function initFiles(files: Ai.Documents.FileDTO[]) {
  return files
    .filter((i) => !!i.documentToken)
    .map((fileDto) => {
      const meta = ref({
        type: fileDto.documentType,
        printable: fileDto.enablePrint || false,
      });

      return reactive({
        token: fileDto.documentToken,
        name: fileDto.documentDescription,
        date: dayjs(
          fileDto.documentDate.substring(0, fileDto.documentDate.length - 6)
        ),
        size: fileDto.documentSize,
        sizeFormatted: prettyBytes(fileDto.documentSize || 0),
        loader: createStreamWorker({ documentToken: fileDto.documentToken }),
        meta: computed(() => meta.value),
      });
    }) as Ai.Documents.File[];
}

async function fetchFileList(folderId: string) {
  const dataService = useData();
  const session = useSession();

  const response = await dataService.invokeStandardMethod<
    StandardMethodResponse<{
      documents: Ai.Documents.FileDTO[];
    }>
  >("ImageRtr_GetDocumentsForPolicy", {
    session: {
      sessionId: session.id,
    },
    parameters: {
      policy: folderId,
    },
  });

  return initFiles(response.data.outputResult.data.documents);
}

export const useDocuments = () => {
  const loading = ref(false);
  const files = ref<Ai.Documents.File[]>([]);

  async function load(folderId: string) {
    try {
      loading.value = true;
      files.value = await fetchFileList(folderId);
    } catch (e) {
      createLogger("documents:load").error(e);
    } finally {
      loading.value = false;
    }
  }

  return reactive({
    loading: readonly(loading),
    files: readonly(files),
    load,
  });
};
