import { maskSeparatorLength } from './mask'

export const validateMaxLength = (
  value: string,
  maxLength: number,
  mask: string = '',
  required?: boolean | null
): string | true => {
  if (maxLength > 0) {
    const separatorLength = maskSeparatorLength(mask || '')

    if (!required && (value === null || value.length == 0)) {
      return true
    }

    if (value.length - separatorLength > maxLength) {
      return `Must not exceed ${maxLength} character${maxLength > 1 ? 's' : ''}`
    }
  }

  return true
}
