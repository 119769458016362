import { createLogger } from '@/logger';
import pLimit from 'p-limit';
import { computed, nextTick, reactive, ref } from 'vue';
import { useData } from '../data';
import { useLaunchParameters } from '../launch-parameters';
import { useSession } from '../session';

export interface UploadOptions {
  fileType: 'PDF';
  base64FileData: string;
  metaData: Record<string, any>;
}

export interface UploadProgress {
  readonly uploadToken: string;
  readonly chunkCount: number;
  readonly completedChunkCount: number;
  readonly percentComplete: number;
}

interface StreamUploadTokenOptions {
  session: { sessionId: string };
  parameters: {
    stream: string;
    chunk: number;
    chunks: number;
    uploadToken: string;
  };
}

const CHUNK_SIZE = 1024 * 1500;
const CONCURRENT_CHUNKS = 5;
const limitChunkConcurrency = pLimit(CONCURRENT_CHUNKS);
const range = (length: number) =>
  Array.apply(null, { length } as any).map(eval.call, Number) as number[];

export function useDocumentUploader() {
  const uploading = ref<boolean>(false);
  const progress = ref<UploadProgress>({
    percentComplete: 0,
    chunkCount: 0,
    completedChunkCount: 0,
    uploadToken: '',
  });
  const uploadError = ref<any>();

  async function upload(options: UploadOptions) {
    try {
      uploading.value = true;
      uploadError.value = null;

      const dataService = useData();
      const chunkCount = Math.ceil(options.base64FileData.length / CHUNK_SIZE);
      const completedChunkCount = ref(0);
      const percentComplete = computed(
        () => completedChunkCount.value / chunkCount
      );
      const uploadToken = await getUploadToken(
        options.metaData,
        options.fileType
      );

      progress.value = reactive({
        uploadToken,
        percentComplete,
        chunkCount,
        completedChunkCount,
      });

      const promises = range(chunkCount).map(async (chunkIndex) => {
        const streamChunkOptions: StreamUploadTokenOptions = {
          session: { sessionId: useSession().id },
          parameters: {
            stream: options.base64FileData.substr(
              chunkIndex * CHUNK_SIZE,
              CHUNK_SIZE
            ),
            chunk: chunkIndex,
            chunks: chunkCount,
            uploadToken: uploadToken,
          },
        };

        return limitChunkConcurrency(async () => {
          try {
            await dataService.invokeStandardMethod(
              'ImageRtr_ReceiveChunk',
              streamChunkOptions,
              { timeout: 300000 }
            );

            // const recievedSize = parseInt(
            //   response.data.outputResult?.data?.token?.dataSize || 0
            // );

            // if (streamChunkOptions.parameters.stream.length !== recievedSize) {
            //   throw new DataSizeMismatchError(
            //     `Sent ${streamChunkOptions.parameters.stream.length} bytes, but the server reported recieving ${recievedSize} bytes. `
            //   );
            // }

            completedChunkCount.value += 1;
          } catch (error) {
            return Promise.reject(error);
          }
        });
      });

      await Promise.all(promises);
      uploading.value = false;

      // return new Promise<void>((resolve) => {
      //   setTimeout(() => {
      //     uploading.value = false;
      //     resolve(undefined);
      //   }, 30);
      // });
      return true;
    } catch (error) {
      progress.value = reactive({
        uploadToken: '',
        percentComplete: 0,
        chunkCount: 0,
        completedChunkCount: 0,
      });

      const message =
        (error instanceof Error && error.message) || error.toString();
      const stack = (error instanceof Error && error.stack) || '';

      createLogger('uploader:upload').error({ message, stack });

      uploading.value = false;
      uploadError.value = error;

      return Promise.resolve(false);
    }
  }

  return reactive({
    uploading,
    progress,
    upload,
  });
}

async function getUploadToken(
  data: Record<string, any>,
  fileType: string = 'PDF'
) {
  const dataService = useData();

  const response = await dataService.invokeStandardMethod(
    'ImageRtr_GetUploadToken',
    {
      session: {
        sessionId: useSession().id,
      },
      parameters: {
        ...useLaunchParameters().value,
        ...data,
        fileType,
      },
    }
  );

  const token = response.data.outputResult.data?.token?.uploadToken || '';

  if (typeof token !== 'string' || !token) {
    throw new Error('Upload token retrieval failed.');
  }

  return token;
}
