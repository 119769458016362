import { computed, reactive, ref, triggerRef, watch } from 'vue';
import { useDocumentTypes } from '../document-types';
import { Form } from '../reactive-form';
import { createDocumentImporter, DocumentImporter } from './document-importer';
import { createPolicyDocumentMetaForm } from './policy-meta-form';
import { UploadProgress, useDocumentUploader } from './uploader';

export interface DocumentImporterContext {
  readonly uploadProgress: UploadProgress;
  readonly uploading: boolean;
  readonly canUpload: boolean;
  readonly importer: DocumentImporter;
  readonly metaForm: Form;
  upload(): Promise<boolean>;
}

export async function useDocumentImporter(
  containerId: string,
  options: {
    width: string;
    height: string;
    columns: number;
    metaParameters: Record<string, any>
  }
) {
  const importer = await createDocumentImporter(containerId, options);
  const metaForm = await createPolicyDocumentMetaForm();
  const uploader = useDocumentUploader();

  watch(
    metaForm,
    () => {
      const documentTypes = useDocumentTypes();
      const docType = documentTypes.items.find(
        (i) => i.id === metaForm.data.documentType
      );

      if (!docType) {
        return;
      }

      if (
        docType.grayscale !== importer.grayscale ||
        docType.bitDepth !== importer.bitDepth ||
        docType.maxDpi !== importer.dpi
      ) {
        //importer.removeAllImages()
        importer.grayscale = docType.grayscale;
        importer.bitDepth = docType.bitDepth;
        importer.dpi = docType.maxDpi;
      }
    },
    { deep: true }
  );

  const canImport = computed(() => {
    return !!metaForm.data.documentType && importer.canImport;
  });
  const canScan = computed(() => {
    return !!metaForm.data.documentType && importer.canScan;
  });

  const canUpload = computed(() => {
    return (
      importer.pageCount > 0 &&
      !metaForm.invalid &&
      !importer.isScanning &&
      !importer.isLoadingScanners
    );
  });

  async function upload() {
    if (canUpload) {
      uploader.uploading = true;
      return await uploader.upload({
        metaData: {
          ...options.metaParameters,
          ...metaForm.data
        },
        fileType: 'PDF',
        base64FileData: await importer.getBase64Pdf(),
      });
    }

    return false;
  }

  // watch(uploader, () => {
  //   console.log(JSON.stringify(uploader.progress));
  // });

  const api = reactive({
    uploadProgress: computed(() => uploader.progress),
    uploading: computed(() => uploader.uploading),
    upload,
    canUpload,
    importer,
    canScan,
    canImport,
    metaForm,
  });

  return api as DocumentImporterContext
}
