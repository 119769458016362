import { AxiosInstance } from 'axios';
import {
  detectEnvironment,
  initAxiosInstance,
  warnForcedOverride,
} from './utilities';

import {
  DataService,
  DataServiceOptions,
  DataServicesPromise,
  DataServicesRequestConfig,
  EnvironmentSelection,
  EnvironmentType,
  FusionServiceResponse,
  ISeriesFusionServiceResponse,
  StandardMethodResponse,
} from './types';
import { InvalidSessionError } from './errors/SessionError';
import { StandardMethodError } from './errors/StandardMethodError';
import { DataServicesError } from './errors/DataServicesError';

const defaultDataServicesClientOptions: DataServiceOptions = {
  environmentTypeOverride: undefined,
  defaultEnvironmentType: EnvironmentType.production,
  hostNameKeywordMap: {
    dev: EnvironmentType.test,
    test: EnvironmentType.test,
    localhost: EnvironmentType.test,
  },
  environmentUrls: {
    local: 'http://localhost:5000',
    test: 'https://testdataservices.firstacceptance.com',
    production: 'https://dataservices.firstacceptance.com',
  },
  disableWarnings: false,
};

export const buildDataServicesOptions = (
  options: Partial<DataServiceOptions> = {}
) => {
  return {
    ...defaultDataServicesClientOptions,
    ...options,
    hostNameKeywordMap: {
      ...defaultDataServicesClientOptions.hostNameKeywordMap,
      ...(options.hostNameKeywordMap ? options.hostNameKeywordMap : {}),
    },
    environmentUrls: {
      ...defaultDataServicesClientOptions.environmentUrls,
      ...(options.environmentUrls ? options.environmentUrls : {}),
    },
  };
};

export class AiDataService implements DataService {
  readonly environment: EnvironmentSelection;
  private readonly axiosInstance: AxiosInstance;

  constructor(options: Partial<DataServiceOptions> = {}) {
    this.environment = detectEnvironment(buildDataServicesOptions(options));
    Object.freeze(this.environment);

    if (!(options || defaultDataServicesClientOptions).disableWarnings) {
      warnForcedOverride(this.environment);
    }

    this.axiosInstance = initAxiosInstance(this.environment);
  }

  public invokeStandardMethod<T = StandardMethodResponse>(
    methodName: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.post<T>(
      `/webservice/method/${methodName}`,
      data,
      {
        ...config,
        transformResponse: (body: any) => {
          try {
            const response = JSON.parse(body) as StandardMethodResponse;

            const session = response.session;
            if (session && !session.isValid) {
              throw new InvalidSessionError(
                'Invalid session',
                response.session.sessionId
              );
            }

            if (!response.outputResult) {
              throw new DataServicesError('Unexpected server response.');
            }

            const methodStatus = response.outputResult.methodStatus;
            if (!methodStatus || !methodStatus.isValid) {
              throw new StandardMethodError(
                methodStatus.error,
                methodStatus.method
              );
            }

            return response;
          } catch (e) {
            throw new DataServicesError(e.message);
          }
        },
      }
    );
  }

  public invokeISeriesService<T = any>(
    methodName: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.post<T>(
      '/fusion',
      {
        service: 'ISeries',
        type: 0,
        data: {
          name: methodName,
          arguments: {
            arguments: data,
          },
        },
      },
      {
        ...config,
        transformResponse: (body: any) => {
          let response: FusionServiceResponse<ISeriesFusionServiceResponse<T>>;

          try {
            response = JSON.parse(body);
          } catch (error) {
            throw new DataServicesError(error.message);
          }

          if (response.error) {
            throw new DataServicesError(response.error.message);
          }

          const iSeriesResponse = response.data;

          if (iSeriesResponse.status !== 200) {
            throw new DataServicesError(
              `ISeries WebServices ` +
              `Request failed with HttpStatus -  ${iSeriesResponse.status} : ${body}`
            );
          }

          if (!iSeriesResponse.content) {
            throw new DataServicesError(`ISeries Webservices Request
            returned no content at path 'content'. ${body}`);
          }

          if (!iSeriesResponse.content.webServiceXml) {
            return iSeriesResponse.content;
          }

          const serviceProgramResponse = iSeriesResponse.content.webServiceXml;

          if ('serviceProgramXML' in serviceProgramResponse) {
            return serviceProgramResponse.serviceProgramXML;
          }

          return serviceProgramResponse;
        },
      }
    );
  }

  public invokeFusionService<T = any>(
    serviceName: string,
    methodName: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.post<T>(
      '/fusion',
      {
        service: serviceName,
        type: 0,
        data: {
          name: methodName,
          arguments: data,
        },
      },
      config
    );
  }

  public get<T = any>(
    url: string,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.get<T>(url, config);
  }

  public delete(
    url: string,
    config?: DataServicesRequestConfig
  ): DataServicesPromise {
    return this.axiosInstance.delete(url, config);
  }

  public head(
    url: string,
    config?: DataServicesRequestConfig
  ): DataServicesPromise {
    return this.axiosInstance.head(url, config);
  }

  public post<T = any>(
    url: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.post<T>(url, data, config);
  }

  public put<T = any>(
    url: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.put<T>(url, data, config);
  }

  public patch<T = any>(
    url: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T> {
    return this.axiosInstance.patch<T>(url, data, config);
  }
}
