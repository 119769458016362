export const validateOneOfSelections = (
  value: any = '',
  validValues: any[] = []
): string | true => {
  if (!Array.isArray(validValues)) {
    return true;
  }

  if (!validValues.includes(value)) {
    return 'Invalid selection';
  }

  return true;
};
