export const validateRequiredValue = (value: any): string | true => {
  const msg = "Required";
  if (null === value || undefined === value) {
    return msg;
  }

  if (typeof value === "string") {
    return !!value || msg;
  } else if (typeof value === "boolean") {
    return !!value || msg;
  }

  if (isFinite(value)) {
    return true;
  }

  return "Required";
};
