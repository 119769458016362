import { maskSeparatorLength } from './mask'

export const validateMinLength = (
  value: string,
  minLength: number,
  mask: string = '',
  maxLength?: number | null,
  required?: boolean | null
): string | true => {
  if (minLength != null && minLength > 0) {
    const separatorLength = maskSeparatorLength(mask || '')

    if (!required && (value === null || value.length == 0)) {
      return true
    }

    if (value === null || value.length - separatorLength < minLength) {
      let prefix = ' '

      if (minLength !== maxLength) {
        prefix = ' at least '
      }

      return `Must be${prefix}${minLength} character${minLength > 1 ? 's' : ''}`
    }
  }

  return true
}
