import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  DataServiceOptions,
  EnvironmentSelection,
  ForcedOverrideType,
} from './types';

export const initAxiosInstance = (
  environment: EnvironmentSelection
): AxiosInstance => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: environment.baseUrl,
    timeout: 30 * 1000, // 30 Seconds
    // maxContentLength: undefined
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'DataServicesClient@1.0.0',
    },
  };

  const instance = axios.create(axiosConfig);

  instance.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      // Do something with response data
      return response;
    },
    (error) => {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return instance;
};

export const warnForcedOverride = (environment: EnvironmentSelection) => {
  if (!environment.forcedOverride) {
    return;
  }
  let message = `DataServices environment set to: ${environment.environmentType} - ${environment.baseUrl}. `;

  if (environment.forcedOverride === ForcedOverrideType.nolocation) {
    message += 'A hostname could not be detected.';
  } else if (environment.forcedOverride === ForcedOverrideType.manual) {
    message += 'The environment was manually overridden.';
  }
};

export const detectEnvironment = (
  options: DataServiceOptions
): EnvironmentSelection => {
  if (options.environmentTypeOverride) {
    return {
      environmentType: options.environmentTypeOverride,
      baseUrl: options.environmentUrls[options.environmentTypeOverride],
      forcedOverride: ForcedOverrideType.manual,
    };
  }

  if (typeof window === 'undefined') {
    return {
      environmentType: options.defaultEnvironmentType,
      baseUrl: options.environmentUrls[options.defaultEnvironmentType],
      forcedOverride: ForcedOverrideType.nolocation,
    };
  }

  const hostName = window.location.hostname.toLowerCase();
  const keyWords = Object.keys(options.hostNameKeywordMap);
  const matchedKeyword = keyWords.find(
    (keyword) => hostName.indexOf(keyword.toLowerCase()) > -1
  );

  if (matchedKeyword) {
    const environmentType = options.hostNameKeywordMap[matchedKeyword];
    return {
      environmentType,
      baseUrl: options.environmentUrls[environmentType],
    };
  }

  return {
    environmentType: options.defaultEnvironmentType,
    baseUrl: options.environmentUrls[options.defaultEnvironmentType],
    forcedOverride: ForcedOverrideType.nomatch,
  };
};
