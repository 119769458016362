import { ref } from '@vue/reactivity';
import { AiDataService, buildDataServicesOptions } from './service';
import { DataService, DataServiceOptions } from './types';
const dataService = ref<AiDataService | null>(null);

export function createDataService(
  options: Partial<DataServiceOptions> = {}
): DataService {
  dataService.value = new AiDataService(buildDataServicesOptions(options));
  return dataService.value;
}

export const useData = () => {
  if (dataService.value === null) {
    throw new Error(
      'Data service has not been created. Did you call createDataService on startup?'
    );
  }

  return dataService.value;
};
