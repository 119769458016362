
import {
  computed,
  defineComponent,
  reactive,
  inject,
  Ref,
  onMounted,
  ref,
} from 'vue';
import TextInput from '@/components/inputs/text.vue';
import ButtonGroupInput from '@/components/inputs/button-group.vue';
import Spinner from '@/components/spinner.vue';

import {
  LockClosedIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';
import { FormField } from '@/hooks/reactive-form';
import { DocumentTreeContext } from '@/hooks/document-tree';

export default defineComponent({
  name: 'MetaForm',
  components: {
    LockClosedIcon,
    ExclamationCircleIcon,
    CheckCircleIcon,
    Spinner,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const documentTree = inject<Ref<DocumentTreeContext>>(
      'DocumentTreeContext'
    );

    const policyNumberField = documentTree?.value.search.fields.find(
      (i) => i.name === 'policyNumber'
    );
    const canHide = ref(false);

    // These should be promoted to a property
    const buttonGroupFields = ['policyType'];

    function resolveFieldComponent(field: FormField) {
      if (buttonGroupFields.includes(field.name)) {
        return ButtonGroupInput;
      }
      return TextInput;
    }

    function resolveFieldComponentProps(field: FormField) {
      const base = {
        id: `a-meta-field-${field.name}`,
        name: field.name,
        loading: computed(() => field.loading),
        readonly: computed(() => field.disabled),
        invalid: computed(() => field.invalid),
        value: computed(() => field.value),
      };

      // This should be handled by the component parent
      if (field.name === 'policyNumber') {
        if (documentTree?.value.search.data.documentType === 'acceptance') {
          (base as any).mask = {
            mask: 'AXXX-#*',
            tokens: {
              A: { pattern: /[a-zA-z]/, uppercase: true },
              X: { pattern: /[0-9a-zA-z]/, uppercase: true },
            },
          };
        } else {
          (base as any).mask = {
            mask: 'X*',
            tokens: {
              X: { pattern: /[0-9a-zA-z\s-]/, uppercase: true },
            },
          };
        }
      }

      if (field.selections?.length) {
        return reactive({
          ...base,
          options: computed(() => field.selections),
        });
      }

      return reactive(base);
    }

    return {
      documentTree,
      policyNumberField,
      fields: computed(() =>
        documentTree && documentTree.value
          ? documentTree.value.search.fields
          : []
      ),
      resolveFieldComponent,
      resolveFieldComponentProps,
    };
  },
});
