
import { computed, defineComponent, inject, Ref, ref } from "vue";
import ScanMenu from "./scanner-selector-button.vue";
import ToggleSwitch from "@/components/toggle-switch.vue";
import Spinner from "@/components/spinner.vue";
import RecordActions from "./record-actions.vue";
import { DocumentImporterContext } from "@/hooks/document-importer";
import { DOCUMENT_IMPORTER_CONTEXT_KEY } from "./common";
import { useRouter } from "vue-router";
import { useSession } from "@/hooks/session";
import { useParentWindow } from "@/hooks/parent-window";

const NO_SCANNERS_AVAILABLE_MESSAGE = "No Scanners Available";

export default defineComponent({
  name: "ImportHeader",
  components: {
    ScanMenu,
    ToggleSwitch,
    Spinner,
    RecordActions,
  },
  setup(props, { slots }) {
    const importerContext = inject<Ref<DocumentImporterContext>>(
      DOCUMENT_IMPORTER_CONTEXT_KEY
    );
    const router = useRouter();

    async function uploadAndClose() {
      if (!importerContext?.value) {
        return;
      }

      useParentWindow().postMessage({
        source: "document-importer",
        event: "file-import-save-begin",
      });

      const uploadResult = await importerContext.value.upload();

      if (!uploadResult) {
        useParentWindow().postMessage({
          source: "document-importer",
          event: "file-import-save-canceled",
        });
        return Promise.reject("Upload incomplete.");
      }

      useParentWindow().postMessage({
        source: "document-importer",
        event: "file-import-save-complete",
      });

      return new Promise((resolve) => {
        setTimeout(async () => {
          await close();
          resolve(true);
          // Allow animations time to complete.
        }, 1000);
      });
    }

    async function cancelAndClose() {
      // if (importerContext?.value.uploading) {
      //   //console.log('TODO: Cancel upload.');
      // }

      useParentWindow().postMessage({
        source: "document-importer",
        event: "file-import-canceled",
      });

      await close();
    }

    async function close() {
      useParentWindow().postMessage({
        source: "document-importer",
        event: "file-import-close",
      });

      const data = importerContext!.value.metaForm.data;

      await router.replace({
        name: "documents",
        query: {
          policyType: data.policyType || "",
          policyNumber: data.policyNumber || "",
          namedInsured: data.namedInsured || "",
          productType: data.productType || "",
          sessionId: useSession().id,
        },
      });
    }

    return {
      uploadAndClose,
      cancelAndClose,
      close,
      importerContext,
      NO_SCANNERS_AVAILABLE_MESSAGE,
    };
  },
});
