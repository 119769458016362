
import { defineComponent } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { createLaunchParameters } from "./hooks/launch-parameters";
import DefaultLayout from "./layouts/DefaultLayout.vue";

export default defineComponent({
  name: "app",
  components: {
    DefaultLayout,
  },
  data() {
    return {};
  },
  computed: {
    layout() {
      return this.$router.currentRoute.value.meta.layout || "default-layout";
    },
  },
  setup() {
    createLaunchParameters(useRoute().query);
  },
});
