
import { defineComponent, ref } from '@vue/runtime-core';
import { maska } from 'maska';

export default defineComponent({
  name: 'TextInput',
  emits: ['update:modelValue', 'blur', 'focus'],
  components: {},
  directives: { maska },
  props: {
    id: { type: String },
    name: { type: String },
    autocomplete: { type: String, default: 'off' },
    value: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    valid: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    mask: {
      type: Object,
    },
  },
  setup(props) {
    return {};
  },
});
