import { computed, reactive, ref } from "vue";
import Dynamsoft from "dwt";
import { createLogger } from "@/logger";
import { useWebTwain } from "../webtwain";
import { useLocalStorage } from "@vueuse/core";

const range = (length: number) =>
  Array.apply(null, { length } as any).map(eval.call, Number) as number[];

export interface DocumentImporter {
  scannerNames: string[];
  selectedScannerName: string | undefined;
  feederAvailable: boolean;
  feederEnabled: boolean;
  duplexAvailable: boolean;
  duplexEnabled: boolean;
  isScanning: boolean;
  isLoadingScanners: boolean;
  isLoadingImages: boolean;
  isConvertingImages: boolean;
  conversionProgress: number;
  grayscale: boolean;
  dpi: number;
  bitDepth: number;
  pageCount: number;
  canScan: boolean;
  canImport: boolean;
  hasPages: boolean;
  zoomIn: () => void;
  zoomOut: () => void;
  rotateRight: () => void;
  rotateLeft: () => void;
  rotateAllRight: () => void;
  rotateAllLeft: () => void;
  removeCurrentImage: () => void;
  removeAllImages: () => void;
  scan: () => void;
  getBase64Pdf: () => Promise<string>;
  refreshScanners: () => Promise<void>;
  selectScanner: (name: string) => void;
  openFileDialog: () => void;
  dispose: () => void;
}

export async function createDocumentImporter(
  containerId: string,
  options: {
    width: string;
    height: string;
    columns: number;
  }
): Promise<DocumentImporter> {
  const scannerNames = ref<string[]>([]);
  const selectedScannerName = useLocalStorage("AI_selectedScanner", "");
  const loadingScanners = ref(false);
  const feederAvailable = ref(true);
  const feederEnabled = ref(false);
  const duplexAvailable = ref(false);
  const duplexEnabled = ref(false);
  const isScanning = ref(false);
  const pageCount = ref(0);
  const isLoadingImages = ref(false);
  const isConvertingImages = ref(false);
  const conversionProgress = ref(0);

  const dpi = ref(200);
  const bitDepth = ref(4);
  const grayscale = ref(true);

  const { instance: webTwain, dispose: disposeWebTwain } = await useWebTwain(
    containerId
  );

  webTwain.RegisterEvent("OnBufferChanged", () => {
    pageCount.value = webTwain.HowManyImagesInBuffer;
  });

  //webTwain.BitDepth = 4

  webTwain.Viewer.border = "solid 1px red;";
  webTwain.Viewer.width = options?.width || "500px";
  webTwain.Viewer.height = options?.height || "500px";
  webTwain.Viewer.border = "";
  webTwain.IfAutoScroll = false;
  webTwain.Viewer.cursor = "pointer";
  // webTwain.Viewer.background = 'rgb(255, 255, 255)';
  //webTwain.Viewer.setViewMode(-1, -1);
  webTwain.Viewer.singlePageMode = false;

  webTwain.Viewer.background = "#FFF";

  webTwain.RegisterEvent("OnPostLoad", (event) => {
    pageCount.value = webTwain.HowManyImagesInBuffer;
    isLoadingImages.value = false;
    isConvertingImages.value = true;
    conversionProgress.value = 0;

    function onImageConverted(index: number) {
      const imageNumber = index + 1;
      if (imageNumber === webTwain.HowManyImagesInBuffer) {
        isConvertingImages.value = false;
        conversionProgress.value = 100;
      } else {
        conversionProgress.value = imageNumber / webTwain.HowManyImagesInBuffer;
      }
    }

    range(webTwain.HowManyImagesInBuffer).forEach((imageIndex) => {
      const imageNumber = imageIndex + 1;
      setTimeout(() => {
        webTwain.SetDPI(
          imageIndex,
          dpi.value,
          dpi.value,
          false,
          Dynamsoft.DWT.EnumDWT_InterpolationMethod.IM_BILINEAR,
          () => {
            setTimeout(() => {
              if (grayscale.value) {
                if (bitDepth.value === 1) {
                  webTwain.ConvertToBW(imageIndex, () =>
                    onImageConverted(imageIndex)
                  );
                } else {
                  webTwain.ConvertToGrayScale(imageIndex, () =>
                    onImageConverted(imageIndex)
                  );
                }
              } else {
                onImageConverted(imageIndex);
              }
            }, 20);
          }
        );
      }, 20);
    });
  });

  // const thumbnailColumnCount = computed(() => {
  //   return 4;
  // });

  const thumbnailViewer = webTwain.Viewer.createThumbnailViewer({
    size: "20%",
    allowKeyboardControl: true,
    allowPageDragging: true,
    showPageNumber: true,
    autoChangeIndex: false,
    // columns: options.columns,
    // rows: 6,
    background: "#FFF",
    pageBorder: "solid 2px #9CA3AF",
    selectedPageBorder: "solid 2px #3B82F6",
    hoverPageBorder: "solid 2px #FDBA74",
    selectedPageBackground: "#F9FAFB",
    hoverPageBackground: "#F9FAFB",
  });
  thumbnailViewer.show();

  const refreshScanners = async () => {
    loadingScanners.value = true;
    try {
      const names = (await webTwain.GetSourceNamesAsync(false)) as string[];
      scannerNames.value = names.map((i: any) => {
        if (typeof i === "string") {
          return i;
        }

        // Should never happen
        return "";
      });

      if (scannerNames.value.length === 1) {
        importerApi.selectScanner(scannerNames.value[0]);
      }

      if (!scannerNames.value.includes(selectedScannerName.value || "")) {
        clearSelectedScanner();
      }

      loadingScanners.value = false;
    } catch (e) {
      loadingScanners.value = false;
      throw e;
    }
  };

  function clearSelectedScanner() {
    selectedScannerName.value = "";
    feederEnabled.value = false;
    feederAvailable.value = true;
    duplexAvailable.value = false;
    duplexEnabled.value = false;
    pageCount.value = 0;
  }

  const importerApi = reactive({
    scannerNames: computed(() => [...scannerNames.value]),
    selectedScannerName: computed(() => selectedScannerName.value),
    grayscale: computed({
      get() {
        return grayscale.value;
      },
      set(value: boolean) {
        grayscale.value = value;
      },
    }),
    dpi: computed({
      get() {
        return dpi.value;
      },
      set(value: number) {
        if (value < 100 || value > 600) {
          return;
        }
        dpi.value = value;
      },
    }),
    bitDepth: computed({
      get() {
        return bitDepth.value;
      },
      set(value: number) {
        bitDepth.value = value;
      },
    }),
    feederAvailable: computed(() => feederAvailable.value),
    feederEnabled: computed({
      get() {
        if (feederAvailable.value) {
          return feederEnabled.value;
        }
        return false;
      },
      set(value: boolean) {
        if (feederAvailable.value) {
          feederEnabled.value = value;
        }
      },
    }),
    duplexAvailable: computed(() => duplexAvailable.value),
    duplexEnabled: computed({
      get() {
        if (duplexAvailable.value) {
          return duplexEnabled.value;
        }
        return false;
      },
      set(value: boolean) {
        if (duplexAvailable.value) {
          duplexEnabled.value = value;
        }
      },
    }),
    isScanning: computed(() => isScanning.value),
    isLoadingScanners: computed(() => loadingScanners.value),
    isLoadingImages: computed(() => isLoadingImages.value),
    isConvertingImages: computed(() => isConvertingImages.value),
    conversionProgress,
    pageCount: computed(() => pageCount.value),
    hasPages: computed(() => importerApi.pageCount > 0),
    canScan: computed(
      () =>
        !loadingScanners.value &&
        !isScanning.value &&
        !!selectedScannerName.value &&
        !isLoadingImages.value &&
        !isConvertingImages.value
    ),
    canImport: computed(
      () =>
        !isScanning.value && !isLoadingImages.value && !isConvertingImages.value
    ),
    refreshScanners,
    selectScanner(name: string) {
      const index = scannerNames.value.indexOf(name);
      if (index > -1 && webTwain.CurrentSourceName !== name) {
        if (webTwain.SelectSourceByIndex(index)) {
          selectedScannerName.value = name;
          duplexAvailable.value = false;
          feederAvailable.value = true;
        }
      }
    },
    openFileDialog() {
      var onSuccess = function () { };
      var onFailure = function (errorCode: number, errorString: string) {
        pageCount.value = webTwain.HowManyImagesInBuffer;
        const ignoreCodes = new Set<number>([-1032, -2006]);
        if (!ignoreCodes.has(errorCode)) {
          createLogger("document-importer:openFileDialog:onFailure").error({
            errorCode,
            errorString,
          });
        }
        isLoadingImages.value = false;
        conversionProgress.value = 0;
      };
      webTwain.IfShowFileDialog = true;

      isLoadingImages.value = true;
      conversionProgress.value = 0;

      webTwain.LoadImageEx(
        "",
        Dynamsoft.DWT.EnumDWT_ImageType.IT_ALL,
        onSuccess,
        onFailure
      );
    },
    scan() {
      isScanning.value = true;

      const cleanup = async () => {
        await webTwain?.CloseSourceAsync().then(() => {
          isScanning.value = false;
          pageCount.value = webTwain.HowManyImagesInBuffer;
        });
      };

      async function acquire() {
        webTwain.IfDisableSourceAfterAcquire = true;
        webTwain.IfShowUI = false;
        webTwain.IfShowIndicator = false;

        webTwain.PageSize = Dynamsoft.DWT.EnumDWT_CapSupportedSizes.TWSS_A4;
        webTwain.IfAutoDiscardBlankpages = true;
        webTwain.IfAutomaticBorderDetection = true;
        webTwain.IfFeederEnabled = feederEnabled.value;
        webTwain.IfDuplexEnabled = duplexEnabled.value;

        webTwain.BitDepth = bitDepth.value;
        webTwain.JPEGQuality = 80;

        if (grayscale.value) {
          if (bitDepth.value === 1) {
            webTwain.PixelType = Dynamsoft.DWT.EnumDWT_PixelType.TWPT_BW;
          } else {
            webTwain.PixelType = Dynamsoft.DWT.EnumDWT_PixelType.TWPT_GRAY;
          }
        } else {
          webTwain.PixelType = Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB;
        }

        await webTwain.AcquireImage(cleanup, cleanup);
      }

      webTwain.OpenSourceAsync().then(acquire, cleanup);
    },

    zoomIn() {
      webTwain.Viewer.zoom += 0.05;
    },
    zoomOut() {
      webTwain.Viewer.zoom -= 0.05;
    },
    rotateRight() {
      webTwain.RotateRight(webTwain.CurrentImageIndexInBuffer);
    },
    rotateLeft() {
      webTwain.RotateLeft(webTwain.CurrentImageIndexInBuffer);
    },
    rotateAllRight() {
      range(pageCount.value).forEach((index) => {
        webTwain.RotateRight(index);
      });
    },
    rotateAllLeft() {
      range(pageCount.value).forEach((index) => {
        webTwain.RotateLeft(index);
      });
    },

    removeAllImages() {
      webTwain.RemoveAllImages();
    },
    removeCurrentImage() {
      webTwain.RemoveImage(webTwain.CurrentImageIndexInBuffer);
    },

    async getBase64Pdf() {
      return new Promise<string>((resolve, reject) => {
        webTwain.ConvertToBase64(
          range(pageCount.value),
          Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
          (result) => {
            resolve(result.getData(0, result.getLength()));
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    dispose() {
      disposeWebTwain();
    },
  });

  try {
    refreshScanners();
  } catch (e) { }

  return importerApi;
}
