import { createApp } from 'vue';
import router from './router';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import App from './App.vue';
import AppLoadingOverlay from './components/app-loading-overlay.vue';

import './styles/index.css';
import { createDataService } from './hooks/data';
import { createSessionService } from './hooks/session';
import { useDocumentTypes } from './hooks/document-types';
import { useProductTypes } from './hooks/product-types';
import { createLogger } from './logger';

async function validateSessionAndMount() {
  const appLogger = createLogger('application');

  try {

    createDataService();
    const session = createSessionService();

    const app = createApp(App).use(router);
    app.config.errorHandler = (err) => {
      if (err instanceof Error) {
        createLogger('app:global').error({
          message: err.message,
          stack: err.stack || '',
        });
      } else {
        createLogger('app:global').error({ error: err });
      }
    };

    await router.isReady();

    const q = router.currentRoute.value.query;
    const valid = await session.validate((q.sessionId as string) || '');

    if (!valid) {
      router.replace('/unauthorized');
    } else {
      createLogger('app').info({ message: 'App Startup' });
      await Promise.all([useProductTypes().load(), useDocumentTypes().load()]);
    }

    app.mount('#app');
    loadingApp.unmount();
  } catch (error) {
    appLogger.error(error as Error);
    createLogger('main:validateSessionAndMount').error(error as Error);
  }
}

const loadingApp = createApp(AppLoadingOverlay);
loadingApp.mount('#loader');

validateSessionAndMount();
