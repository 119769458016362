
import TWEEN from '@tweenjs/tween.js';
import { templateRef, useElementSize } from '@vueuse/core';
import { computed, defineComponent, reactive, ref, watch } from 'vue-demi';

export default defineComponent({
  name: 'ProgressBarCircle',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    strokeWidth: {
      type: Number,
      default: 10,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'rgba(229, 231, 235, 1)',
    },
    progressColor: {
      type: String,
      default: 'rgba(59, 130, 246, 1)',
    },
    textStyle: {
      type: String,
      default: 'absolute inset-0 grid place-content-center',
    },
    showPercentSign: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const el = templateRef<HTMLDivElement>('el');
    const size = useElementSize(el);
    const radius = ref(50);

    watch(props, () => {}, { immediate: true });

    const state = reactive({
      tweenedValue: ref(0),
      stroke: computed(() => 2 * Math.PI * radius.value),
      strokeOffset: computed(() =>
        props.inverted
          ? state.stroke * (state.tweenedValue / 100) + state.stroke
          : state.stroke * ((100 - state.tweenedValue) / 100)
      ),
    });
    const tween = ref();

    watch(props, () => {
      if (tween.value) {
        tween.value.end();
      }

      function animate() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      }

      tween.value = new TWEEN.Tween<{ tweenedValue: number }>(state)
        .to({ tweenedValue: props.value }, 1000)
        .start();

      animate();
    });

    return {
      size,
      radius,
      state,
    };
  },
});
