
import { defineComponent, onMounted, onUnmounted, provide, ref } from "vue";
import { useRoute } from "vue-router";

import { useDocumentImporter, DocumentImporterContext } from "@/hooks/document-importer";
import { DOCUMENT_IMPORTER_CONTEXT_KEY } from "./common";

import MetaForm from "./meta-form.vue";
import ImportHeader from "./import-header.vue";
import EditHeader from "./edit-header.vue";
import ImageImportOverlay from "../image-import-overlay.vue";

import { createLogger } from "@/logger";
import { useMagicKeys } from "@vueuse/core";

export default defineComponent({
  name: "DocumentImporter",
  components: {
    MetaForm,
    ImportHeader,
    EditHeader,
    ImageImportOverlay,
  },
  setup() {
    const loading = ref(true);
    const loadingError = ref<{ title: string; description: string } | null>(null);

    const importerContext = ref<DocumentImporterContext | null>(null);
    provide(DOCUMENT_IMPORTER_CONTEXT_KEY, importerContext);

    const route = useRoute();
    loading.value = true;

    const init = async () => {
      try {
        loading.value = true;

        useMagicKeys({
          passive: false,
          onEventFired(e) {
            if (
              importerContext.value?.uploading ||
              !importerContext.value?.importer.hasPages ||
              !importerContext.value.importer.isLoadingImages ||
              !importerContext.value.importer.isConvertingImages
            ) {
              return;
            }

            if ((e.metaKey || e.ctrlKey) && e.key === "=" && e.type === "keydown") {
              e.preventDefault();
              importerContext.value?.importer.zoomIn();
            } else if (
              (e.metaKey || e.ctrlKey) &&
              e.key === "-" &&
              e.type === "keydown"
            ) {
              e.preventDefault();
              importerContext.value?.importer.zoomOut();
            }
          },
        });

        importerContext.value = await useDocumentImporter(
          "ai-policy-document-container",
          {
            width: "100%",
            height: "100%",
            columns: window.innerWidth <= 768 ? 1 : window.innerWidth <= 1024 ? 1 : 1,
            metaParameters: route.query,
          }
        );

        importerContext.value.metaForm.data = route.params;

        // Disable if these params are passed in via the querystring

        importerContext.value.metaForm.getField("documentType")?.disabled ===
          !!route.params.documentType;
        importerContext.value.metaForm.getField("productType")?.disabled ===
          !!route.params.productType;

        loading.value = false;
      } catch (error) {
        loading.value = false;
        loadingError.value = { title: error.messsage, description: "" };
        createLogger("document-importer-main:init").error(error);
      }
    };

    onMounted(() => {
      init();
    });

    onUnmounted(() => {
      importerContext.value?.importer.dispose();
    });

    return {
      loading,
      importerContext,
    };
  },
});
