import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute top-0 bottom-0 left-0 right-0 z-[1000] grid w-full h-full overflow-hidden text-center bg-white opacity-100 place-content-center" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Spinner, { class: "w-12 h-12 mb-4" }),
      _createVNode("h2", {
        class: "text-xl font-semibold text-blue-700",
        textContent: _toDisplayString(_ctx.title)
      }, null, 8, ["textContent"]),
      _createVNode("p", {
        class: "text-gray-700",
        textContent: _toDisplayString(_ctx.description)
      }, null, 8, ["textContent"])
    ])
  ]))
}