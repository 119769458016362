export const validateMinValue = (
  value: number | null,
  minValue: number,
  maxValue?: number | null,
  required?: boolean | null
): string | true => {
  if (!required && value === null) {
    return true;
  }

  if (!isFinite(minValue)) {
    return true;
  }

  if (value == null || value < minValue) {
    let prefix = "Must not be less than ";

    if (minValue === maxValue) {
      prefix = "Must be equal to ";
    }

    return `${prefix}${minValue}`;
  }

  return true;
};
