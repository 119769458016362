import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "relative flex flex-1" }
const _hoisted_3 = { class: "flex flex-col flex-grow h-full max-h-[calc(100vh-72px)]" }
const _hoisted_4 = { class: "grid flex-grow overflow-hidden rounded-tl-md" }
const _hoisted_5 = {
  ref: "containerParent",
  class: "relative w-full h-full overflow-hidden bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_import_header = _resolveComponent("import-header")
  const _component_edit_header = _resolveComponent("edit-header")
  const _component_MetaForm = _resolveComponent("MetaForm")
  const _component_ImageImportOverlay = _resolveComponent("ImageImportOverlay")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_import_header, {
      style: {"z-index":"999"},
      class: "bg-white shadow-md"
    }),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_edit_header, { class: "bg-white" }),
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", {
              id: "ai-policy-document-container",
              class: ["absolute inset-0 w-full h-full !border-transparent", {
                'opacity-0': _ctx.loading || _ctx.importerContext?.importer.isLoadingImages,
              }]
            }, null, 2)
          ], 512)
        ])
      ]),
      (!_ctx.loading || _ctx.importerContext?.importer.isLoadingImages)
        ? (_openBlock(), _createBlock(_component_MetaForm, {
            key: 0,
            class: "bg-white border-r border-gray-200 shadow-md w-[320px] lg:w-[420px] h-screen z-10"
          }))
        : _createCommentVNode("", true),
      (
          _ctx.importerContext?.importer.isScanning ||
          _ctx.importerContext?.importer.isLoadingImages ||
          _ctx.importerContext?.importer.isConvertingImages
        )
        ? (_openBlock(), _createBlock(_component_ImageImportOverlay, {
            key: 1,
            progress: _ctx.importerContext?.importer.conversionProgress * 100,
            title: 
          _ctx.importerContext?.importer.isConvertingImages
            ? 'Optimizing Images'
            : _ctx.importerContext?.importer.isScanning
            ? 'Scanning'
            : ''
        
          }, null, 8, ["progress", "title"]))
        : _createCommentVNode("", true)
    ])
  ]))
}