
import { computed, onMounted, provide, Ref, ref, watch } from "vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  XIcon,
  DocumentTextIcon,
  PlusCircleIcon,
} from "@heroicons/vue/outline";
import {
  ChevronRightIcon,
  DotsVerticalIcon,
  SearchIcon,
} from "@heroicons/vue/solid";
import { DocumentTreeContext, useDocumentTree } from "@/hooks/document-tree";
import SearchForm from "./search-form.vue";
import Spinner from "../spinner.vue";
import ViewerSlideover from "./viewer-slideover.vue";
import { useRoute, useRouter } from "vue-router";
import { useResizeObserver } from "@vueuse/core";
import TweenNumber from "@/components/tween-number.vue";
import ProgressCircle from "@/components/progress-circle.vue";
import { useParentWindow } from "@/hooks/parent-window";

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    DocumentTextIcon,
    ChevronRightIcon,
    DotsVerticalIcon,
    SearchIcon,
    XIcon,
    PlusCircleIcon,
    SearchForm,
    Spinner,
    ViewerSlideover,
    TweenNumber,
    ProgressCircle,
  },
  setup() {
    const documentsContainer = ref<HTMLElement>(null as any);
    const tableContainer = ref<HTMLElement>(null as any);
    const documentTree = ref(useDocumentTree());
    const router = useRouter();
    const route = useRoute();
    const canShowSearchForm = ref(route.query.hideSearch !== "true");
    const loaded = ref(false);

    documentTree.value.search.data = {
      ...route.query,
      policyType: route.query.policyType || "acceptance",
      policyNumber: route.query.policyNumber || "",
    };

    provide<Ref<DocumentTreeContext>>("DocumentTreeContext", documentTree);

    const hasInvalidPolicyNumber = computed(() => {
      return documentTree.value.search.fields.find(
        (i) => i.invalidText.toLowerCase() === "invalid policy number"
      );
    });

    onMounted(async () => {
      useResizeObserver(documentsContainer.value, (val) => {
        tableContainer.value.style.maxHeight = `${val[0].contentRect.height}px`;
      });

      if (documentTree.value.canLoad) {
        await documentTree.value.load();
        loaded.value = true;
      } else if (!documentTree.value.canLoad) {
        const quit = watch(
          documentTree,
          () => {
            if (documentTree.value.canLoad) {
              quit();
              documentTree.value.load().then(() => (loaded.value = true));
            }
          },
          { deep: true }
        );
      }
    });

    function showImporter() {
      router.push({
        name: "import",
        params: {
          ...route.query,
          ...documentTree.value.search.data,
        },
      });
    }

    const importDisabled = computed(() => {
      return !loaded.value || documentTree.value.loading || route.query.readonly === 'true';
    });

    return {
      canShowSearchForm,
      documentsContainer,
      tableContainer,
      documentTree,
      parentWindow: useParentWindow(),
      loaded,
      importDisabled,
      showImporter,
      hasInvalidPolicyNumber,
    };
  },
};
