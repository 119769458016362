import { AxiosResponse, AxiosRequestConfig } from 'axios';

export enum ForcedOverrideType {
  manual = 'manual',
  nolocation = 'nolocation',
  nomatch = 'nomatch',
}

export enum EnvironmentType {
  local = 'local',
  test = 'test',
  production = 'production',
}

export interface FusionServiceResponse<T> {
  type: number;
  services: string;
  invocationId: string;
  data: T;
  error?: { message: string };
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ISeriesFusionServiceResponse<T> {
  status: number;
  content: {
    webServiceXml: {
      serviceProgramXML: T;
      programErrors: {
        errorCode: string;
        errorDescription: string;
      };
    };
  };
}

export type CancelStatic = new (message?: string) => Cancel;

export interface Cancel {
  message: string;
}

export type Canceler = (message?: string) => void;

export interface CancelTokenStatic {
  new (executor: (cancel: Canceler) => void): CancelToken;
  source(): CancelTokenSource;
}

export interface CancelToken {
  promise: Promise<Cancel>;
  reason?: Cancel;
  throwIfRequested(): void;
}

export interface CancelTokenSource {
  token: CancelToken;
  cancel: Canceler;
}

export type DataServicesEnvironmentUrls = {
  [key in keyof typeof EnvironmentType]: string;
};

export interface DataServiceOptions {
  environmentTypeOverride?: EnvironmentType;
  defaultEnvironmentType: EnvironmentType;
  environmentUrls: DataServicesEnvironmentUrls;
  hostName?: string;
  hostNameKeywordMap: {
    [key: string]: EnvironmentType;
  };
  disableWarnings?: boolean;
}

export interface EnvironmentSelection {
  environmentType: EnvironmentType;
  baseUrl: string;
  forcedOverride?: ForcedOverrideType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataServicesRequestConfig extends AxiosRequestConfig {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataServicesResponse<T = any> extends AxiosResponse<T> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DataServicesPromise<T = any>
  extends Promise<DataServicesResponse<T>> {}

export interface DataService {
  readonly environment: EnvironmentSelection;
  invokeStandardMethod<D extends {} = {}>(
    methodName: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<StandardMethodResponse<D>>;
  invokeISeriesService<T = any>(
    methodName: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T>;
  invokeFusionService<T = any>(
    serviceName: string,
    methodName: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T>;
  get<T = any>(
    url: string,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T>;
  delete(url: string, config?: DataServicesRequestConfig): DataServicesPromise;
  head(url: string, config?: DataServicesRequestConfig): DataServicesPromise;
  post<T = any>(
    url: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T>;
  put<T = any>(
    url: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T>;
  patch<T = any>(
    url: string,
    data?: any,
    config?: DataServicesRequestConfig
  ): DataServicesPromise<T>;
}

export interface StandardMethodResponse<T = any> {
  outputResult: {
    data: T;
    methodStatus: {
      error: string;
      isValid: boolean;
      method: string;
    };
  };
  session: {
    isValid: boolean;
    sessionId: string;
  };
}

export interface DataServicesPluginServiceOptions extends DataServiceOptions {
  id: string;
}
