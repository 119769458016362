/**
 * Excludes all valid mask placeholders and returns the length of the remaining characters
 */
export const maskSeparatorLength = (mask: string) => {
  // https://github.com/probil/v-mask#readme
  return mask
    .replace(/#/g, '')
    .replace(/X/g, '')
    .replace(/S/g, '')
    .replace(/A/g, '')
    .replace(/a/g, '')
    .replace(/!/g, '')
    .replace(/\*/g, '')
    .replace(/\?/g, '').length
}
