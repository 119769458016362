import dayjs from 'dayjs';

export interface Logger {
  info(data: any): void;
  warn(data: any): void;
  error(data: any): void;
}

export function createLogger(category: string = '') {
  function createDoc(level: 'warn' | 'error', data: Record<string, any>) {
    switch (level) {
      case 'warn':
        console.warn(`[${dayjs().format('YYYY-MM-DD HH:mm:ss')}] [${category}]`, data);
        break;
      case 'error':
        console.error(`[${dayjs().format('YYYY-MM-DD HH:mm:ss')}] [${category}]`, data);
        break;
    }
  }

  return {
    info(data: Record<string, any>) {
      //createDoc('info', data);
    },
    warn(data: Record<string, any>) {
      createDoc('warn', data);
    },
    error(data: Record<string, any>) {
      createDoc('error', data);
    },
  };
}
