export * from '../documents';
export * from './types';

import { computed, reactive, ref } from 'vue';
import { Form } from '../reactive-form';
import { useDocuments } from '../documents';
import { createPolicyDocumentSearchForm } from './document-search-form';
import { Ai } from './types';

export interface DocumentTreeContext {
  readonly search: Form;
  readonly files: Ai.Documents.File[];
  activeFile: Ai.Documents.File | null;
  loading: boolean;
  canLoad: boolean;
  load(): Promise<void>;
}

export function useDocumentTree(): DocumentTreeContext {
  const search = createPolicyDocumentSearchForm();
  const canLoad = computed(
    () => !search.invalid && !search.getField('policyNumber')?.loading
  );
  const documents = useDocuments();
  const files = computed(() => documents.files);
  const loading = computed(() => documents.loading);
  const activeFile = ref<Ai.Documents.File | null>(null);

  return (reactive({
    search,
    files,
    loading,
    canLoad,
    activeFile,
    async load() {
      if (canLoad.value) {
        documents.load(search.data.policyNumber);
      }
    },
  }) as any) as DocumentTreeContext;
}
