import { createLogger } from '@/logger';
import { reactive, ref } from '@vue/reactivity';
import { computed } from '@vue/runtime-core';
import { useData } from './data';
import { useSession } from './session';

export interface DocumentType {
  id: string;
  title: string;
  grayscale: boolean;
  maxDpi: number;
  bitDepth: number;
}

const items = ref<DocumentType[]>([]);

export function useDocumentTypes() {
  const loading = ref(false);

  const model = {
    loading: computed(() => loading.value),
    items: computed(() => [...items.value]),
  };

  async function load() {
    try {
      loading.value = true;

      const dataService = useData();
      const session = useSession();

      const { data } = await dataService.invokeStandardMethod(
        'IMAGERTR_GETSCANNINGDOCUMENTTYPES',
        {
          session: {
            sessionId: session.id,
          },
        }
      );

      const src = data.outputResult.data.documentTypes;

      if (Array.isArray(src)) {
        items.value = src.map((i) => ({
          id: (i.id || '').trim(),
          title: (i.description || '').trim(),
          grayscale: i.grayscale || false,
          maxDpi: parseInt(i.maxDpi) || 200,
          bitDepth: parseInt(i.bitDepth || '4') || 4,
        }));
      }
    } catch (e) {
      createLogger('document-types:load').error(e);
    } finally {
      loading.value = false;
    }
  }

  return reactive({
    ...model,
    load,
  });
}
