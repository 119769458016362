import { computed, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useDocumentTypes } from "../document-types";

import { usePolicyValidator } from "../policy-validator";
import { useProductTypes } from "../product-types";
import {
  useForm,
  Form,
  FormFieldType,
  FormFieldOptions,
} from "../reactive-form";

export * from "../reactive-form/types";
const ACCEPTANCE_POLICY_TYPE = "acceptance";

function initForm() {
  const policyValidator = usePolicyValidator();
  const expandedPolicyNumber = ref("");
  const validPolicyNumber = ref(true);

  const form = reactive<Form>(
    useForm(
      reactive({
        fields: (computed(() =>
          [
            {
              name: "productType",
              type: FormFieldType.string,
              label: "Product Type",
              validation: {
                required: true,
              },
              selections: () => useProductTypes().items,
            },
            {
              name: "documentType",
              type: FormFieldType.string,
              label: "Document Type",
              validation: {
                required: true,
              },
              selections: () => useDocumentTypes().items,
            },
            {
              name: "policyType",
              type: FormFieldType.string,
              label: "Policy Type",
              validation: {
                required: true,
              },
              selections: [
                { id: "acceptance", title: "Acceptance" },
                { id: "3PC", title: "3rd Party (3PC)" },
              ],
            },
            {
              name: "policyNumber",
              type: FormFieldType.string,
              label: "Policy Number",
              validation: {
                required: true,
              },
              customValidator: () => {
                return validPolicyNumber.value ? true : "Invalid Policy Number";
              },
            },
            {
              name: "namedInsured",
              type: FormFieldType.string,
              label: "Insured Name",
              validation: {
                required: true,
              },
            },
          ].filter((i) => !!i)
        ) as any) as FormFieldOptions[],
        value: {},
      })
    )
  );

  const namedInsuredField = form.fields.find((i) => i.name === "namedInsured");
  const policyTypeField = form.fields.find((i) => i.name === "policyType");
  const policyNumberField = form.fields.find((i) => i.name === "policyNumber");
  const { policySearchDisabled } = { policySearchDisabled: true }; //useParentWindow();

  if (!policySearchDisabled) {
    watch(policyValidator, () => {
      policyNumberField!.loading = policyValidator.loading;
      (expandedPolicyNumber.value as any) = policyNumberField!.value || "";

      if (!policyValidator.loading) {
        validPolicyNumber.value = policyValidator.found;
        if (policyValidator.found) {
          policyNumberField!.value =
            policyValidator.policy!.expandedPolicyNumber ||
            policyNumberField?.value ||
            "";
          namedInsuredField!.disabled = false;
          namedInsuredField!.value = policyValidator.policy?.namedInsured || "";
          namedInsuredField!.disabled = true;
        }
      } else {
        validPolicyNumber.value = true;
      }
    });

    watch(form, () => {
      if (namedInsuredField) {
        namedInsuredField.disabled =
          form.data["policyType"] === ACCEPTANCE_POLICY_TYPE;
      }

      if (policyTypeField!.value === ACCEPTANCE_POLICY_TYPE) {
        if (policyNumberField!.value !== expandedPolicyNumber.value) {
          policyValidator.load((form.data.policyNumber as string) || "");
        }
      } else {
        validPolicyNumber.value = true;
      }
    });
  } else {
    setTimeout(() => {
      policyNumberField!.disabled = policySearchDisabled;
      policyTypeField!.disabled = policySearchDisabled;
      namedInsuredField!.disabled = policySearchDisabled;
    }, 0);
  }

  return form;
}

export function createPolicyDocumentMetaForm() {
  return initForm();
}
