import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col p-4 bg-white sm:flex-row"
}
const _hoisted_2 = { class: "flex flex-wrap gap-y-2 " }
const _hoisted_3 = { class: "flex justify-end flex-grow" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScanMenu = _resolveComponent("ScanMenu")
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")
  const _component_record_actions = _resolveComponent("record-actions")

  return (_ctx.importerContext)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_ScanMenu, { class: "z-[999] flex-shrink-0 mr-4" }),
          (_ctx.importerContext.importer)
            ? (_openBlock(), _createBlock(_component_ToggleSwitch, {
                key: 0,
                disabled: !_ctx.importerContext.importer.canScan,
                title: "Feeder",
                modelValue: _ctx.importerContext.importer.feederEnabled,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.importerContext.importer.feederEnabled = !!$event)),
                class: "pr-4 mr-4"
              }, null, 8, ["disabled", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode("button", {
            type: "button",
            class: ["items-center col-span-1 px-6 py-2 mr-4 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm lg:mt-0 whitespace-nowrap hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500", {
          'opacity-40 pointer-events-none': !_ctx.importerContext.canScan,
        }],
            disabled: !_ctx.importerContext.canScan,
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.importerContext.importer.scan()), ["prevent"]))
          }, " Scan ", 10, ["disabled"]),
          _createVNode("button", {
            type: "button",
            class: ["items-center col-span-1 px-4 py-2 mr-4 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm lg:mt-0 whitespace-nowrap hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500", {
          'opacity-40 pointer-events-none': !_ctx.importerContext.canImport,
        }],
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.importerContext.importer.openFileDialog()), ["prevent"])),
            disabled: !_ctx.importerContext.canImport
          }, " Select Files ", 10, ["disabled"])
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_record_actions, {
              onSaveClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.uploadAndClose())),
              onCancelClicked: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelAndClose()))
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}