export type FormFieldValueType = any;

export interface Form<T extends Record<string, any> = Record<string, any>> {
  readonly invalid: boolean;
  readonly fields: FormField[];
  data: T;
  getField(name: string): FormField | undefined;
  touchAll(): void;
  untouchAll(): void;
}

export interface FormOptions<
  T extends Record<string, any> = Record<string, any>
> {
  fields: FormFieldOptions[];
  value?: T;
}

export enum FormFieldType {
  'string' = 'string',
  'number' = 'number',
  'boolean' = 'boolean',
  'date' = 'date',
}

export interface FormField {
  readonly name: string;
  readonly type: FormFieldType;
  readonly label: string;
  readonly indicatorStatus?: 'normal' | 'success' | 'failure' | 'warning';
  readonly hint: string;
  readonly invalid: boolean;
  readonly invalidText: string;
  disabled: boolean;
  loading: boolean;
  touched: boolean;
  value: any;
  selections: SelectOption<any>[];
}
export interface StringField extends FormField {
  type: FormFieldType.string;
  value: string;
  selections: SelectOption<string>[];
}
export interface NumberField extends FormField {
  type: FormFieldType.number;
  value: number;
  selections: SelectOption<number>[];
}
export interface BooleanField extends FormField {
  type: FormFieldType.boolean;
  value: boolean;
  selections: SelectOption<boolean>[];
}
export interface DateField extends FormField {
  type: FormFieldType.date;
  value: string;
  selections: SelectOption<string>[];
}

export interface TextValidationOptions {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

export interface NumberValidationOptions {
  required?: boolean;
  minValue?: number;
  maxValue?: number;
}

export interface DateValidationOptions {
  required?: boolean;
  minDate?: string;
  maxDate?: string;
}

export interface BooleanValidationOptions {
  required?: boolean;
}

export interface SelectOption<T extends FormFieldValueType = string> {
  id: T;
  title: string;
}

export type SelectionsResolver<T extends FormFieldValueType> =
  | SelectOption<T>[]
  | (() => SelectOption<T>[]);

export interface FormFieldOptions<
  V extends Record<string, any> = Record<string, any>
> {
  readonly name: string;
  readonly label: string;
  readonly type: FormFieldType;
  readonly hint?: string;
  readonly validation?: V;
  readonly selections?: SelectionsResolver<FormFieldValueType>;
  customValidator?: (field: FormField) => true | string;
}

export interface TextFieldOptions
  extends FormFieldOptions<{
    required?: boolean;
    minLength?: number;
    maxLength?: number;
  }> {
  readonly type: FormFieldType.string;
  readonly validation?: TextValidationOptions;
  readonly value?: string;
  readonly selections?: SelectionsResolver<string>;
}

export interface NumberFieldOptions
  extends FormFieldOptions<{
    required?: boolean;
    minValue?: number;
    maxValue?: number;
  }> {
  readonly type: FormFieldType.number;
  readonly validation?: NumberValidationOptions;
  readonly value?: number;
  readonly selections?: SelectionsResolver<number>;
}

export interface BooleanFieldOptions
  extends FormFieldOptions<{ required?: boolean }> {
  readonly type: FormFieldType.boolean;
  readonly validation?: BooleanValidationOptions;
  readonly value?: boolean;
  readonly selections?: SelectionsResolver<boolean>;
}

export interface DateFieldOptions
  extends FormFieldOptions<{
    required?: boolean;
    minDate?: string | Date;
    maxDate?: string | Date;
  }> {
  readonly type: FormFieldType.date;
  readonly validation?: DateValidationOptions;
  readonly value?: string;
  readonly selections?: SelectionsResolver<string>;
}

export type FieldValidator<T extends FormField = FormField> = (
  field: T,
  fieldOptions: FormFieldOptions
) => string | true;
