import Dynamsoft from 'dwt';
import { ref } from 'vue';
import { createLogger } from '@/logger';
import { WebTwain } from 'dwt/dist/types/WebTwain';

const webTwainKey = 'f0068lQAAAMmaC9tAgCYwqBxosEKcbkyZ6eJmyR9KdXlyHmr8ujeN01dsNHloQVVFIYZrj86KwiuZ4kzbhOxxphzu2WJlMUU='
const raterizerKey = 'f0068lQAAAAnHZ3EYpru/0yHrITRVETaH4/OjRp4Bunsw2qLwUAysIltYZAKYAeXt40baoSuqIPOCzCcpmYi+EgkVO8DZn54='


Dynamsoft.DWT.ResourcesPath = 'dwt-resources';
Dynamsoft.DWT.ProductKey = [webTwainKey, raterizerKey].join(';')
Dynamsoft.DWT.ResourcesPath = '/dwt-resources';

const loaded = ref(false);
const loadingPromise = ref<Promise<void>>();

export async function createWebTwain(): Promise<void> {
  if (loaded.value) {
    return Promise.resolve();
  }

  if (loadingPromise.value) {
    return loadingPromise.value;
  }

  try {
    loadingPromise.value = new Promise((resolve, reject) => {
      Dynamsoft.DWT.RegisterEvent('OnWebTwainReady', () => {
        try {
          loaded.value = true;
        } catch (e) {
          reject(e);
        }
      });
      Dynamsoft.DWT.Load();
    });

    return loadingPromise.value;
  } catch (e) {
    createLogger('document-viewer:initDWT').error(e);
    throw e;
  }
}

export function useWebTwain(containerId) {
  return new Promise<{ instance: WebTwain; dispose: () => void }>(
    (resolve, reject) => {
      Dynamsoft.DWT.CreateDWTObject(
        containerId,
        (instance) => {
          instance.Addon.PDF.SetResolution(200);
          instance.Addon.PDF.SetConvertMode(
            Dynamsoft.DWT.EnumDWT_ConvertMode.CM_RENDERALL
          );

          resolve({
            instance,
            dispose() {
              instance.UnbindViewer();
              Dynamsoft.DWT.DeleteDWTObject(containerId);
            },
          });
        },
        reject
      );
    }
  );
}
