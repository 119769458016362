
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import { Ai } from "@/hooks/document-tree";
import ProgressCircle from "../progress-circle.vue";
import { useWebTwain } from "@/hooks/webtwain";
import { createLogger } from "@/logger";
import { useMagicKeys } from "@vueuse/core";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/vue/outline";

interface DocumentViewer {
  pageCount: number;

  showFile(file: Ai.Documents.File): Promise<void>;
  zoomIn(): void;
  zoomOut(): void;
  rotateRight(): void;
  rotateLeft(): void;
  rotateAllRight(): void;
  rotateAllLeft(): void;
  print: () => void;
  dispose: () => void;
}

const range = (length: number) =>
  Array.apply(null, { length } as any).map(eval.call, Number) as number[];

async function createDocumentViewer(containerId) {
  const pageCount = ref(0);

  const webTwain = await useWebTwain(containerId);

  const { instance, dispose } = webTwain;

  instance.Viewer.width = "100%";
  instance.Viewer.height = "100%";
  instance.Viewer.border = "";
  instance.IfAutoScroll = false;
  instance.Viewer.cursor = "pointer";
  instance.Viewer.background = "rgb(229, 231, 235)";

  const thumbnailViewer = instance.Viewer.createThumbnailViewer({
    allowKeyboardControl: true,
    background: "white",
    size: 200,
    allowResizing: false,
  });
  thumbnailViewer.show();

  return (reactive({
    pageCount: computed(() => pageCount.value),
    zoomIn() {
      instance.Viewer.zoom += 0.05;
    },
    zoomOut() {
      instance.Viewer.zoom -= 0.05;
    },
    rotateRight() {
      instance.RotateRight(instance.CurrentImageIndexInBuffer);
    },
    rotateLeft() {
      instance.RotateLeft(instance.CurrentImageIndexInBuffer);
    },
    rotateAllRight() {
      range(pageCount.value).forEach((index) => {
        instance.RotateRight(index);
      });
    },
    rotateAllLeft() {
      range(pageCount.value).forEach((index) => {
        instance.RotateLeft(index);
      });
    },
    print() {
      instance.Print(false);
    },
    async showFile(file: Ai.Documents.File) {
      try {
        if (!file.loader.result) {
          throw new Error("File has not been loaded.");
        }

        await instance.LoadImageFromBinary(
          file.loader.result,
          () => {},
          (errorCode, errorString) => {
            createLogger("document-viewer:LoadImageFromBinary").error({
              source: "WebTwain",
              errorCode,
              errorString,
            });
          }
        );
      } catch (e) {
        createLogger("document-viewer:showFile").error(e);
      }
    },
    dispose() {
      webTwain.dispose();
    },
  }) as any) as Promise<DocumentViewer>;
}

const containerId = ref(0);

function getNextContainerId() {
  return `ai-document-viewer-${(containerId.value += 1)}`;
}

export default defineComponent({
  name: "DocumentViewer",
  components: { ProgressCircle, ZoomInIcon, ZoomOutIcon },
  props: {
    file: { type: Object, required: true },
  },
  setup(props) {
    const containerId = getNextContainerId();
    const documentViewer = ref<DocumentViewer | null>(null);
    const file = props.file as Ai.Documents.File;
    provide("DocumentViewer", documentViewer);

    const init = async () => {
      try {
        documentViewer.value = await createDocumentViewer(containerId);

        if (!file.loader.result) {
          await file.loader.start();
        }

        if (file.loader.result) {
          await documentViewer.value!.showFile(props.file as Ai.Documents.File);
        }

        useMagicKeys({
          passive: false,
          onEventFired(e) {
            if (
              (e.metaKey || e.ctrlKey) &&
              e.key === "=" &&
              e.type === "keydown"
            ) {
              e.preventDefault();
              documentViewer.value?.zoomIn();
            } else if (
              (e.metaKey || e.ctrlKey) &&
              e.key === "-" &&
              e.type === "keydown"
            ) {
              e.preventDefault();
              documentViewer.value?.zoomOut();
            }
          },
        });
      } catch (error) {
        createLogger("document-viewer:init").error(error);
      }
    };

    onMounted(() => {
      init();
    });

    onUnmounted(() => {
      documentViewer.value && documentViewer.value.dispose();
    });

    return {
      documentViewer,
      file,
      containerId,
    };
  },
});
