
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { Component, defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const swit = ref<any | null>(null);

    nextTick(() => {
      emit('update:modelValue', !props.modelValue || false);
      nextTick(() => {
        emit('update:modelValue', !props.modelValue || false);
      });
    });
    return {
      swit,
    };
  },
});
