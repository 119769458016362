
import { inject, Ref, ref } from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { DocumentTreeContext } from '@/hooks/document-tree';
import DocumentViewer from '@/components/document-viewer/main.vue';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    DocumentViewer,
  },
  emits: ['close'],
  setup() {
    const documentTree = inject<Ref<DocumentTreeContext>>(
      'DocumentTreeContext'
    );

    return {
      documentTree,
    };
  },
};
