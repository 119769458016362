import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")
  const _component_SwitchLabel = _resolveComponent("SwitchLabel")
  const _component_SwitchGroup = _resolveComponent("SwitchGroup")

  return (_openBlock(), _createBlock(_component_SwitchGroup, {
    as: "div",
    class: "flex items-center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Switch, {
        ref: "swit",
        value: _ctx.modelValue,
        class: ["relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500", [
        !!_ctx.modelValue ? 'bg-blue-600' : 'bg-gray-200',
        _ctx.disabled ? 'opacity-40 pointer-events-none' : '',
      ]],
        onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.disabled && _ctx.$emit('update:modelValue', !_ctx.modelValue || false)))
      }, {
        default: _withCtx(() => [
          _createVNode("span", {
            class: "sr-only",
            textContent: _toDisplayString(_ctx.title)
          }, null, 8, ["textContent"]),
          _createVNode("span", {
            "aria-hidden": "true",
            class: [
          _ctx.modelValue ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]
          }, null, 2)
        ]),
        _: 1
      }, 8, ["value", "class"]),
      _createVNode(_component_SwitchLabel, {
        as: "span",
        class: "ml-3"
      }, {
        default: _withCtx(() => [
          _createVNode("span", {
            class: ["text-sm font-medium", [_ctx.disabled ? 'opacity-40' : '']],
            textContent: _toDisplayString(_ctx.title)
          }, null, 10, ["textContent"]),
          _createVNode("span", {
            class: "text-sm text-gray-500",
            textContent: _toDisplayString(_ctx.subtitle)
          }, null, 8, ["textContent"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}