import { Session, SessionService } from './types';
import { ref } from '@vue/reactivity';
import { DataService } from '../data/types';
import { useData } from '../data';
import { createLogger } from '@/logger';

const SESSION_KEY = 'session';
const session = ref<Session | null>(null);

export const createSessionService = (
  dataService: DataService = useData()
): SessionService => {
  const clear = () => {
    session.value = null;
  };


  const validate = async (sessionId: string) => {
    try {
      const response = await dataService.invokeISeriesService(
        'GetPortalSessionUser',
        {
          sessionId,
        }
      );

      if (!response.data?.portalSession?.validSession) {
        return false;
      }

      session.value = {
        id: response.data?.portalSession?.sessionId ?? '',
        userId: (response.data?.portalSession?.user ?? '').trim(),
        name: (response.data?.agentDetails?.fullName ?? '').trim(),
      };

      sessionStorage.setItem(SESSION_KEY, JSON.stringify(session.value));

      return true;
    } catch (e) {
      sessionStorage.removeItem(SESSION_KEY);
      createLogger('session:validate').error(e);
      return false;
    }
  };

  return {
    get currentSession() {
      if (session.value) {
        return {
          ...session.value,
        };
      }

      try {
        const val = sessionStorage.getItem(SESSION_KEY);
        if (val) {
          return JSON.parse(val);
        }
      } catch {
        return null;
      }

      return null;
    },
    clear,
    validate,
  };
};

export const useSession = () => {
  if (!session.value) {
    throw new Error(
      'Session service has not been created. Did you call createSessionService on startup?'
    );
  }

  return session.value;
};
