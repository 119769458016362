
import { defineComponent, inject, ref, Ref } from 'vue';
import { DocumentImporterContext } from '@/hooks/document-importer';
import { DOCUMENT_IMPORTER_CONTEXT_KEY } from './common';
import { useRouter } from 'vue-router';

import { TrashIcon, ZoomInIcon, ZoomOutIcon } from '@heroicons/vue/outline';

export default defineComponent({
  name: 'ImportHeader',
  components: {
    TrashIcon,
    ZoomInIcon,
    ZoomOutIcon,
  },
  setup(props, { slots }) {
    const dotsMenuOpen = ref(false);

    const importerContext = inject<Ref<DocumentImporterContext>>(
      DOCUMENT_IMPORTER_CONTEXT_KEY
    );
    const router = useRouter();

    return {
      importerContext,
      dotsMenuOpen,
    };
  },
});
